import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { demoThings } from '../../../../../core/demo/things.demo';
import { Thing } from '@al-ko/types';

@Component({
  selector: 'app-main-search',
  standalone: true,
  imports: [
    FormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
  ],
  templateUrl: './main-search.component.html',
  styleUrl: './main-search.component.scss',
})
export class MainSearchComponent {
  things: Thing[] = demoThings;
  suggestions: string[] = [
    'Apple',
    'Banana',
    'Cherry',
    'Date',
    'Fig',
    'Grape',
    'Honeydew',
  ];
  filteredSuggestions: string[] = [];
  filteredThings: Thing[] = [];
  searchTerm = '';

  onInputChange() {
    // if (this.searchTerm) {
    //   this.filteredSuggestions = this.suggestions.filter((suggestion) =>
    //     suggestion.toLowerCase().includes(this.searchTerm.toLowerCase()),
    //   );
    // } else {
    //   this.filteredSuggestions = [];
    // }
    if (this.searchTerm) {
      const selection = this.things.filter(
        (thing) =>
          thing.accessInformation?.accessAlias
            ?.toLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          thing.accessInformation?.issuerEmail
            ?.toLocaleLowerCase()
            .includes(this.searchTerm.toLowerCase()) ||
          thing.thingAttributes?.serialNumber
            ?.toLocaleLowerCase()
            .includes(this.searchTerm.toLowerCase()),
      );
      if (selection.length > 5) {
        this.filteredThings = selection.slice(0, 5);
      } else {
        this.filteredThings = selection;
      }
    } else {
      this.filteredThings = [];
    }
  }

  selectSuggestion(suggestion: string) {
    this.searchTerm = suggestion;
    this.filteredSuggestions = [];
    this.filteredThings = [];
  }
}
