<h2 mat-dialog-title>Settings</h2>

<mat-dialog-content>
  <p>how should the cards look like</p>
  <mat-form-field>
    <mat-label>Card Appearance</mat-label>
    <mat-select>
      @for (a of cardApearances; track a) {
        <mat-option [value]="a.value">{{ a.viewValue }}</mat-option>
      }
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-button (click)="onNoClick()">abort</button>
  <button mat-button cdkFocusInitial>save</button>
</mat-dialog-actions>
