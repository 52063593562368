import { AfterViewInit, Component } from '@angular/core';
import { NavigationMenuDesktopComponent } from './navigation-menu-desktop/navigation-menu-desktop.component';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterModule,
} from '@angular/router';
import { filter } from 'rxjs';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
// import { downlo } from '@fortawesome/free-regular-svg-icons';

type AlkoMenuItem = {
  label: string;
  url: string;
  icon?: string;
  hoverIcon?: string;
  svgIcon?: string;
  hover: boolean;
  fontSet?: string;
};

@Component({
  selector: 'app-navigation-menu',
  imports: [MatSidenavModule, MatListModule, MatIconModule, RouterModule],
  templateUrl: './navigation-menu.component.html',
  styleUrl: './navigation-menu.component.scss',
})
export class NavigationMenuComponent implements AfterViewInit {
  public isContentPage: boolean = false;
  public menu: AlkoMenuItem[] = [
    {
      label: 'News',
      icon: 'info',
      hoverIcon: 'info',
      url: '/pages/news',
      hover: false,
    },
    {
      label: 'Devices',
      svgIcon: 'ALKO-ROBOLINHO',
      // icon: 'home_iot_device',
      url: '/pages/devices',
      hover: false,
    },
    {
      label: 'Downloads',
      // icon: 'download-box-outline',
      icon: 'download',
      // icon: 'info_outline',
      // icon: 'cloud_download',
      // icon: 'fa-clipboard-check',
      // fontSet: 'fa',
      url: '/pages/downloads',
      hover: false,
    },
    {
      label: 'Warranty',
      // icon: 'rate_review',
      // icon: 'workspace_premium',
      icon: 'approval',
      url: '/pages/warranty-requests',
      hover: false,
    },
    {
      label: 'Usb',
      icon: 'usb',
      url: '/pages/webusb',
      hover: false,
    },
    {
      label: 'Training',
      icon: 'school',
      url: '/pages/trainings',
      hover: false,
    },
  ];

  constructor(private router: Router) {}

  ngAfterViewInit(): void {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        const navigationEvent = event as NavigationEnd;
        this.isContentPage =
          navigationEvent.urlAfterRedirects.startsWith('/pages');
      });
  }
}
