import { PimInformation, Thing } from '@al-ko/types';
import { Observable } from 'rxjs';
import { ThingState } from '../domain/thingstate.type';
import { FotaStatus } from '../domain/fota-status.enum';

export abstract class IDeviceRepository {
  abstract getAllDevices(): Observable<Thing[]>;
  abstract getDeviceById(thingName: string): Observable<Thing>;
  abstract getReportedStateOfDevice(thingName: string): Observable<ThingState>;
  abstract setDesiredState(
    thingName: string,
    desiredState: any,
  ): Observable<ThingState>;
  abstract getFotaStatus(
    thingName: string,
  ): Observable<{ fotaStatus: FotaStatus; targetVersion: string }>;
  // abstract getProductInformation(
  //   articleNumber: string,
  // ): Observable<PimInformation>;
}

// export interface DeviceRepository { <--- interface geht nicht weil useclass in app.config sonst nicht greift
//   getAllDevices(): Observable<Thing[]>;
//   getDeviceById(thingName: string): Observable<Thing>;
//   subscribeToDevice(thingName: string): Observable<any>;
//   unsubscribeToDevice(thingName: string): void;
// }
