import { RouterModule, Routes } from '@angular/router';

import { authGuard } from './data/guards/auth.guard';

import { NgModule } from '@angular/core';
import { config } from 'rxjs';
import { LoginCallbackComponent } from './presenter/components/login/login-callback/login-callback.component';
import { LoginPageComponent } from './presenter/components/login/login-page/login-page.component';

export const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () =>
      import('./presenter/pages/pages.routes').then((mod) => mod.routes),
    canActivate: [authGuard],
  },
  {
    path: 'login',
    component: LoginPageComponent,
  },
  {
    path: 'home',
    redirectTo: 'pages',
  },
  {
    path: 'oauth',
    component: LoginCallbackComponent,
  },
  {
    path: 'login-callback',
    component: LoginCallbackComponent,
  },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: '**',
    redirectTo: 'login',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
