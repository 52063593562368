import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import {
  catchError,
  EMPTY,
  from,
  Observable,
  switchMap,
  throwError,
} from 'rxjs';
import { OAuthService } from 'angular-oauth2-oidc';

const DOMAIN_production = 'api.al-ko.com';
const DOMAIN_staging = 'staging.al-ko.com';
const DOMAIN_dev = 'dev.al-ko.com';

@Injectable()
export class AlkoApiInterceptor implements HttpInterceptor {
  constructor(
    private authService: OAuthService,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.isAlkoDomain(request)) {
      const req = this.addAcceptedLanguage(
        this.addAuthenticationToRequest(request),
      );
      // return next.handle(req); // früher
      return next.handle(req).pipe(
        catchError((error, caught) => {
          // console.log('[AlkoApiInterceptor] catchError:', error);
          // console.log('[AlkoApiInterceptor] caught:', caught);
          if (error.status === 401 && this.authService.hasValidAccessToken()) {
            return this.handle401Error(request, next);
          } else {
            return throwError(() => error);
          }
        }),
      );
    } else {
      return next.handle(request);
    }
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    console.log(
      '[AlkoApiInterceptor] alko api responded with 401 unauthorizred. trigger refresh and retry request.',
    );
    return from(this.authService.refreshToken()).pipe(
      switchMap(() => {
        // retry reqquest after refreshed token
        const modifiedReq = request.clone({
          setHeaders: {
            Authorization: `Bearer ${this.authService.getAccessToken()}`,
          },
        });
        return next.handle(modifiedReq);
      }),
      catchError((error: any) => {
        console.error('[AlkoApiInterceptor] Refresh token failed:', error);
        // Log the user out or display an error message
        this.authService.logOut();
        return throwError(() => error);
      }),
    );
  }

  private isAlkoDomain(request: HttpRequest<unknown>): boolean {
    return (
      request.url.includes(DOMAIN_production) ||
      request.url.includes(DOMAIN_staging) ||
      request.url.includes(DOMAIN_dev)
    );
  }

  private hasAuthentication(request: HttpRequest<unknown>): boolean {
    return request.headers.get('Authentication') !== null;
  }

  private addAuthenticationToRequest(
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    const token = this.authService.getAccessToken();
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  private addAcceptedLanguage(
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        'Accept-Language': this.locale === 'en-US' ? 'en-GB' : this.locale,
      },
    });
  }
}
