import { Observable } from 'rxjs';
import { MetaDataModel } from '../domain/metadata.type';

export type MetaDataResponse = {
  thingName: string;
  queriedAttributes: { attribute: string; attributeValue: any }[];
};

export abstract class IMetaRepository {
  abstract getMetadata(thingName: string): Observable<MetaDataResponse>;
  abstract createMetadata(thingName: string, metadata: MetaDataModel);
}
