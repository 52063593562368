import { PimInformation } from '@al-ko/types';
import { inject } from '@angular/core';
import {
  ArticleMinInfo,
  IPimInteractor,
} from '@interactors/contracts/ipim.interactor';
import { ArticleMapper } from '@mappers/article.mapper';
import { GetProductInfoUsecase } from '@usecases/pim-usecases/get-product-info.usecase';
import { ResolveErrorDescriptionUsecase } from '@usecases/pim-usecases/resolve-error-description.usecase';
import { ResolveErrorDescriptionsUsecase } from '@usecases/pim-usecases/resolve-error-descriptions.usecase';
import { SearchByArticleNumberUsecase } from '@usecases/pim-usecases/search-by-articlenumber.usecase';
import { map, Observable } from 'rxjs';
import { ErrorDescription } from 'src/app/core/domain/error-description.type';
import { ProductInformation } from 'src/app/core/domain/ProductInformation.type';

export class PimInteractor extends IPimInteractor {
  private mapper = new ArticleMapper();

  private searchProductByArticleNumberUseCase = inject(
    SearchByArticleNumberUsecase,
  );
  private usecaseGetProductInfo = inject(GetProductInfoUsecase);
  private usecaseResolveErrorDescription = inject(
    ResolveErrorDescriptionUsecase,
  );
  private usecaseResolveErrorDescriptions = inject(
    ResolveErrorDescriptionsUsecase,
  );

  override searchProductByArticleNumber(
    articleNumber: string,
  ): Observable<ArticleMinInfo[]> {
    return this.searchProductByArticleNumberUseCase.execute(articleNumber);
  }
  override searchProductByArticleTitle(
    title: string,
  ): Observable<ArticleMinInfo[]> {
    throw new Error('Method not implemented.');
  }

  override getKnowledgeItemsTags(): Observable<
    { key: string; doc_count: number }[]
  > {
    throw new Error('Method not implemented.');
  }

  override getProductInformation(
    articleNumber: string,
  ): Observable<ProductInformation> {
    return this.usecaseGetProductInfo.execute(articleNumber).pipe(
      map((x) => {
        return this.mapper.mapFrom(x);
      }),
    );
  }

  override getProductInformations(
    articleNumbers: string[],
  ): Observable<{ [articleNumber: string]: ProductInformation }> {
    throw new Error('Method not implemented.');
  }

  override getSparepartsOfProduct(
    articleNumber: string,
  ): Observable<ProductInformation[]> {
    throw new Error('Method not implemented.');
  }

  override getTranslations(
    translationPool: string,
  ): Observable<{ [key: string]: string }> {
    throw new Error('Method not implemented.');
  }

  override getErrorDescriptions(): Observable<{
    [errorCode: number]: ErrorDescription;
  }> {
    throw new Error('kein bock');
  }
}
