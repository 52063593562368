@if (isContentPage) {
  <app-main-toolbar></app-main-toolbar>
}

<mat-drawer-container class="sidenav-container" autosize>
  <mat-drawer
    [opened]="isContentPage"
    #drawer
    class="sidenav-drawer"
    mode="side"
  >
    <app-navigation-menu></app-navigation-menu>
  </mat-drawer>

  <!-- <div class="content-container"> -->
  <!-- <button type="button" mat-button (click)="drawer.toggle()">
        Toggle sidenav
      </button> -->
  <!-- <ng-content></ng-content>
    </div> -->
  <mat-drawer-content class="content-container">
    <!-- <div class="loading-indicator-container"> -->
    @if (isLoading()) {
      <mat-progress-bar mode="query"></mat-progress-bar>
    }
    <!-- </div> -->
    <ng-content></ng-content>
  </mat-drawer-content>
</mat-drawer-container>
