import { Thing } from '@al-ko/types';
import { Mapper } from '../../core/base/mapper';
import { AlkoThing, ThingWeb } from 'src/app/core/domain/thing.type';
import { ArticleMapper } from './article.mapper';

export class ThingMapper extends Mapper<ThingWeb, AlkoThing> {
  override mapFrom(param: ThingWeb): AlkoThing {
    const articleMapper = new ArticleMapper();
    return {
      id: param.thingName,
      attributes: param.thingAttributes,
      state: param['thingState']?.state?.reported,
      accessInformation: param.accessInformation,
      pimInformation: articleMapper.mapFrom(param.pim),
      type: param.thingType,
    };
  }

  override mapTo(param: AlkoThing): ThingWeb {
    return {
      thingName: param.id,
      thingAttributes: param.attributes,
      thingType: param.type,
      accessInformation: param.accessInformation,
      pim: undefined,
    };
  }
}
