import { PimInformation, Thing } from '@al-ko/types';
import { Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { ThingState } from '../../../core/domain/thingstate.type';
import { IDeviceRepository } from '../../../core/repositories/device.repository';
import { demoThings } from '../../../core/demo/things.demo';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { FotaStatus } from '../../../core/domain/fota-status.enum';

@Injectable({
  providedIn: 'root',
})
export class DeviceWebRepository extends IDeviceRepository {
  constructor(private http: HttpClient) {
    super();
  }

  setDesiredState(
    thingName: string,
    desiredState: any,
  ): Observable<ThingState> {
    throw new Error('Method not implemented.');
  }

  getReportedStateOfDevice(thingName: string): Observable<ThingState> {
    throw new Error('Method not implemented.');
  }

  getAllDevices(): Observable<Thing[]> {
    const url = `${environment.baseUrl}/iot/things`;
    return this.http.get<Thing[]>(url);
  }

  getDeviceById(thingName: string): Observable<Thing> {
    const url = `${environment.baseUrl}/iot/things/${thingName}`;
    return this.http.get<Thing>(url);
  }

  override getFotaStatus(
    thingName: string,
  ): Observable<{ fotaStatus: FotaStatus; targetVersion: string }> {
    throw new Error('Method not implemented.');
  }
}
