import { PimInformation, Thing } from '@al-ko/types';
import { Mapper } from '../../core/base/mapper';
import { AlkoThing } from 'src/app/core/domain/thing.type';
import { ProductInformation } from 'src/app/core/domain/ProductInformation.type';

export class ArticleMapper extends Mapper<PimInformation, ProductInformation> {
  override mapFrom(pim: PimInformation): ProductInformation {
    if (pim) {
      const appimages = pim.appimages?.map((image) => image.image.fullpath);
      return {
        appimage: appimages && appimages.length > 0 ? appimages[0] : undefined,
        articleNumber: pim['artnumber'],
        attributeList: pim.attributeList.map((attribute) => {
          return {
            name: attribute.name,
            value: attribute.value_once,
          };
        }),
        brand: pim.brand.map((brand) => brand.name)[0],
        documents: pim.additionalDocuments.map((doc) => {
          return {
            name: doc.docname,
            type: doc.doctype,
            version: doc.version,
          };
        }),
        images: pim.images.map((image) => image.fullpath),
        spareparts: pim['spareparts']?.map((sparepart) => {
          return {
            articleNumber: pim['artnumber'],
            title: pim.title,
          };
        }),
        title: pim.title,
      };
    }
    return undefined;
  }

  override mapTo(param: ProductInformation): PimInformation {
    throw new Error('dont need');
  }
}
