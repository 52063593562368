import { Thing } from "@al-ko/types";

export const demoThings: Thing[] = JSON.parse(JSON.stringify([
  {
      "thingName": "113280-7A05-J09321-0008020006",
      "thingType": "ALKO-NON-CONNECTED-DEVICE",
      "thingAttributes": {
          "thingName": "113280-7A05-J09321-0008020006",
          "thingType": "ALKO-NON-CONNECTED-DEVICE",
          "articleNumber": "113280",
          "serialNumber": "7A05-J09321"
      },
      "accessInformation": {
          "accessId": "bd66a2dd-5a47-4820-bc90-9c12c8a1096c",
          "thingName": "113280-7A05-J09321-0008020006",
          "userId": "0008020010",
          "userEmail": "thomas.echerer@al-ko.com",
          "accessAlias": "TestPowerheadChristian",
          "accessAdmin": false,
          "accessCreated": "2023-06-29T13:31:57.524Z",
          "issuerId": "0008020006",
          "issuerEmail": "christian.kranemann@al-ko.com",
          "accessExpires": "2023-12-29T13:53:39.094Z"
      },
      "accesses": [
          {
              "accessId": "bd66a2dd-5a47-4820-bc90-9c12c8a1096c",
              "thingName": "113280-7A05-J09321-0008020006",
              "userId": "0008020010",
              "userEmail": "thomas.echerer@al-ko.com",
              "accessAlias": "TestPowerheadChristian",
              "accessAdmin": false,
              "accessCreated": "2023-06-29T13:31:57.524Z",
              "issuerId": "0008020006",
              "issuerEmail": "christian.kranemann@al-ko.com",
              "accessExpires": "2023-12-29T13:53:39.094Z"
          }
      ],
      "thingState": {
          "state": {
              "desired": {},
              "reported": {
                  "isConnected": false
              },
              "delta": {}
          }
      },
      "pimInformation": {
          "artnumber": "113280",
          "accessoryList": [
              {
                  "id": "2392",
                  "artnumber": "113281"
              },
              {
                  "id": "2754",
                  "artnumber": "113786"
              },
              {
                  "id": "5562",
                  "artnumber": "127573"
              }
          ],
          "price": 0,
          "brand": [
              {
                  "id": "169",
                  "name": "AL-KO"
              }
          ],
          "additionalDocuments": [
              {
                  "doctype": "BED",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC5B091EDA84B97C54EA7CB69B",
                  "docname": "Manual_113280_03_441630.pdf",
                  "version": "03"
              },
              {
                  "doctype": "KONF",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC45F61EDA94FBC4613F9168B7",
                  "docname": "Conf_113280_01_442792.pdf",
                  "version": "01"
              },
              {
                  "doctype": "SICH",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC6FD81EEAAD9174AC1EFBF6BA",
                  "docname": "Safety_113280_02_469663.pdf",
                  "version": "02"
              }
          ],
          "title": "Battery B150 Li 4 Ah - 144 Wh",
          "productHierarchy": "G1990012",
          "crosssellingList": null,
          "attributeList": [
              {
                  "id": "91304",
                  "key": "GT_AKKU_FAMILIE_002",
                  "name": "GT_AKKU_FAMILIE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "AL-KO 36 V",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": "",
                  "parent": {
                      "id": "333",
                      "name": "GT_AKKU_FAMILIE",
                      "label": "Battery Family",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "90018",
                  "key": "GT_MARKE_001",
                  "name": "GT_MARKE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "AL-KO",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": "",
                  "parent": {
                      "id": "181",
                      "name": "GT_MARKE",
                      "label": "Brand",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              }
          ],
          "appimages": null,
          "images": [
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1624536954",
                      "filename": "113280.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/113280.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1624536954",
                      "filename": "al-ko_energy_flex_produktreihe_v2_13.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/al-ko_energy_flex_produktreihe_v2_13.jpg"
                  }
              }
          ]
      }
  },
  {
      "thingName": "119925-8C20-005254-0008020010",
      "thingType": "ALKO-NON-CONNECTED-DEVICE",
      "thingAttributes": {
          "thingName": "119925-8C20-005254-0008020010",
          "thingType": "ALKO-NON-CONNECTED-DEVICE",
          "articleNumber": "119925",
          "serialNumber": "8C20-005254"
      },
      "accessInformation": {
          "accessId": "4485832b-5fd1-4c02-baf5-c86251af7987",
          "thingName": "119925-8C20-005254-0008020010",
          "userId": "0008020010",
          "userEmail": "thomas.echerer@al-ko.com",
          "accessAlias": "AL-KO Mähroboter Robolinho® 500 W",
          "accessAdmin": true,
          "accessCreated": "2023-08-16T09:41:55.796Z"
      },
      "accesses": [
          {
              "accessId": "4485832b-5fd1-4c02-baf5-c86251af7987",
              "thingName": "119925-8C20-005254-0008020010",
              "userId": "0008020010",
              "userEmail": "thomas.echerer@al-ko.com",
              "accessAlias": "AL-KO Mähroboter Robolinho® 500 W",
              "accessAdmin": true,
              "accessCreated": "2023-08-16T09:41:55.796Z"
          }
      ],
      "thingState": {
          "state": {
              "desired": {},
              "reported": {
                  "isConnected": false
              },
              "delta": {}
          }
      },
      "pimInformation": {
          "artnumber": "119925",
          "accessoryList": [
              {
                  "id": "3662",
                  "artnumber": "119461"
              },
              {
                  "id": "3663",
                  "artnumber": "119462"
              },
              {
                  "id": "84411",
                  "artnumber": "119990"
              },
              {
                  "id": "5341",
                  "artnumber": "127327"
              },
              {
                  "id": "5349",
                  "artnumber": "127335"
              },
              {
                  "id": "5469",
                  "artnumber": "127465"
              },
              {
                  "id": "5470",
                  "artnumber": "127466"
              },
              {
                  "id": "5515",
                  "artnumber": "127513"
              },
              {
                  "id": "5516",
                  "artnumber": "127514"
              },
              {
                  "id": "5518",
                  "artnumber": "127516"
              },
              {
                  "id": "162528",
                  "artnumber": "127699"
              }
          ],
          "price": 0,
          "brand": [
              {
                  "id": "169",
                  "name": "AL-KO"
              }
          ],
          "additionalDocuments": [
              {
                  "doctype": "BED",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC45F61ED992DE77827DE6A6E8",
                  "docname": "Manual_119925_01_495053.pdf",
                  "version": "01"
              },
              {
                  "doctype": "BED",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EDCA3FBDDC5FA5FF353",
                  "docname": "Manual_119925_06_495439.pdf",
                  "version": "06"
              },
              {
                  "doctype": "KONF",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EDCA3FBC5841D77128E",
                  "docname": "Conf_119925_04_442812.pdf",
                  "version": "04"
              }
          ],
          "title": "Lawnmower Robolinho 500 W",
          "productHierarchy": "G1050011",
          "crosssellingList": [
              {
                  "artnumber": "127465",
                  "id": "5469"
              }
          ],
          "attributeList": [
              {
                  "id": "93227",
                  "key": "GT_ECO_MODE_002",
                  "name": "GT_ECO_MODE",
                  "label": null,
                  "attrtype": "string",
                  "value_once": "No",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": "",
                  "parent": {
                      "id": "86669",
                      "name": "GT_ECO_MODE",
                      "label": "Device has eco mode",
                      "exportToShop": true,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "93228",
                  "key": "GT_KONNEKTIVITAET_003",
                  "name": "GT_KONNEKTIVITAET",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "AL-KO Connect WLAN",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": "",
                  "parent": {
                      "id": "334",
                      "name": "GT_KONNEKTIVITAET",
                      "label": "Connectivity",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "153178",
                  "key": "GT_MAEHSTRATEGIE_001",
                  "name": "GT_MAEHSTRATEGIE",
                  "label": null,
                  "attrtype": "string",
                  "value_once": "001",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": "",
                  "parent": {
                      "id": "95979",
                      "name": "GT_MAEHSTRATEGIE",
                      "label": "GT_MAEHSTRATEGIE",
                      "exportToShop": false,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "92330",
                  "key": "GT_REGENSENSOR_002",
                  "name": "GT_REGENSENSOR",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "No",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": null,
                  "parent": {
                      "id": "392",
                      "name": "GT_REGENSENSOR",
                      "label": "Rain Sensor",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93229",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_002",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Amazon Alexa",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "162630",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_004",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "004",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "004",
                  "unit": "",
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93230",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_003",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "IFTTT",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "90018",
                  "key": "GT_MARKE_001",
                  "name": "GT_MARKE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "AL-KO",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": "",
                  "parent": {
                      "id": "181",
                      "name": "GT_MARKE",
                      "label": "Brand",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "90408",
                  "key": "GT_PRODUKTLINIE_002",
                  "name": "GT_PRODUKTLINIE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Comfort",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": "",
                  "parent": {
                      "id": "184",
                      "name": "GT_PRODUKTLINIE",
                      "label": "Product Range",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93104",
                  "key": "GT_AKKU_INTERN_LADEZEIT_MIN_50_0",
                  "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 50,
                  "internalValue": "50_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "336",
                      "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                      "label": "Charging Time Internal Battery",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92335",
                  "key": "GT_AKKU_INTERN_LAUFZEIT_MIN_55_0",
                  "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 55,
                  "internalValue": "55_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "337",
                      "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                      "label": "Battery Run Time (internal)",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92665",
                  "key": "GT_EINSTIEGSPUNKTE_N_3_0",
                  "name": "GT_EINSTIEGSPUNKTE_N",
                  "label": null,
                  "attrtype": "int",
                  "value_once": null,
                  "value_to": 0,
                  "value_from": 3,
                  "internalValue": "3_0",
                  "unit": null,
                  "parent": {
                      "id": "391",
                      "name": "GT_EINSTIEGSPUNKTE_N",
                      "label": "Number of Entry Points",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "96069",
                  "key": "GT_GESCHW_VORWAERTS_MAX_KMH_0_97",
                  "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                  "label": null,
                  "attrtype": "float",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 0.97,
                  "internalValue": "0_97",
                  "unit": "KMH",
                  "parent": {
                      "id": "357",
                      "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                      "label": "Forward Speed max km/h",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92667",
                  "key": "GT_SCHNITTBREITE_CM_20_0",
                  "name": "GT_SCHNITTBREITE_CM",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 20,
                  "internalValue": "20_0",
                  "unit": "CM",
                  "parent": {
                      "id": "269",
                      "name": "GT_SCHNITTBREITE_CM",
                      "label": "Cutting Width in cm",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              }
          ],
          "appimages": [
              {
                  "image": {
                      "mimetype": "image/png",
                      "update": "1611304162",
                      "filename": "robolinho500.png",
                      "fullpath": "https://pim.al-ko.com/products/inTouchApp/robolinho500.png"
                  }
              }
          ],
          "images": null
      }
  },
  {
      "thingName": "7f0763440876b365f5e70d2e7a16e0bca6deb5ea",
      "thingType": "ALKO-ROBOLINHO",
      "thingAttributes": {
          "thingName": "7f0763440876b365f5e70d2e7a16e0bca6deb5ea",
          "thingType": "ALKO-ROBOLINHO",
          "articleNumber": "127695",
          "serialNumber": "3B24-082457",
          "firmwareWifi": "1.9.5",
          "hardwareVersionWifi": "1D",
          "firmwareMain": "2302A-SW",
          "firmwareMainLocalization": "SW",
          "serialNumberMain": "3B24-082457",
          "hardwareVersionMain": "1D",
          "thingModel": "Robolinho520",
          "firmwareWifiDriver": "V19.6.1",
          "serialNumberWifi": "012319DC12BE4A80EE",
          "fotaMode": "off",
          "betatester": "False"
      },
      "accessInformation": {
          "accessId": "ec87b290-6109-422e-9bd2-fdf1fcfa8e3a",
          "thingName": "7f0763440876b365f5e70d2e7a16e0bca6deb5ea",
          "userId": "0008020010",
          "userEmail": "thomas.echerer@al-ko.com",
          "accessAlias": "schulungszentrum",
          "accessAdmin": true,
          "accessCreated": "2023-10-26T09:37:10.780Z"
      },
      "accesses": [
          {
              "accessId": "ec87b290-6109-422e-9bd2-fdf1fcfa8e3a",
              "thingName": "7f0763440876b365f5e70d2e7a16e0bca6deb5ea",
              "userId": "0008020010",
              "userEmail": "thomas.echerer@al-ko.com",
              "accessAlias": "schulungszentrum",
              "accessAdmin": true,
              "accessCreated": "2023-10-26T09:37:10.780Z"
          },
          {
              "accessId": "ce79ac02-8844-4f2e-a98b-bb449139df55",
              "thingName": "7f0763440876b365f5e70d2e7a16e0bca6deb5ea",
              "userId": "0008103042",
              "userEmail": "oliver.mientus@al-ko.com",
              "accessAlias": "schule",
              "accessAdmin": false,
              "accessCreated": "2023-11-06T15:42:59.450Z",
              "issuerId": "0008020010",
              "issuerEmail": "thomas.echerer@al-ko.com",
              "accessExpires": "2023-12-29T13:53:39.094Z"
          },
          {
              "accessId": "fd2af1db-fee0-41c3-96bd-ecfd4e897f9a",
              "thingName": "7f0763440876b365f5e70d2e7a16e0bca6deb5ea",
              "userId": "0008109287",
              "userEmail": "stella.fleis@cobeisfresh.com",
              "accessAlias": "Schulungszentrum",
              "accessAdmin": false,
              "accessCreated": "2023-12-12T14:04:31.413Z",
              "issuerId": "0008020010",
              "issuerEmail": "thomas.echerer@al-ko.com"
          },
          {
              "accessId": "d98dddf1-027d-4c83-9c14-d33fe6ea8164",
              "thingName": "7f0763440876b365f5e70d2e7a16e0bca6deb5ea",
              "userId": "0008109287",
              "userEmail": "stella.fleis@cobeisfresh.com",
              "accessAlias": "Schulungszentrum",
              "accessAdmin": false,
              "accessCreated": "2023-12-12T15:10:52.585Z",
              "issuerId": "0008020010",
              "issuerEmail": "thomas.echerer@al-ko.com"
          }
      ],
      "thingState": {
          "state": {
              "desired": {
                  "mowingWindows": {
                      "monday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "tuesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "wednesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "thursday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": true,
                              "narrowPassageMode": false,
                              "startHour": 14,
                              "startMinute": 15,
                              "duration": 130,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 15,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "friday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "saturday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "sunday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": true,
                              "narrowPassageMode": true,
                              "startHour": 3,
                              "startMinute": 0,
                              "duration": 33,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": true,
                              "narrowPassageMode": true,
                              "startHour": 2,
                              "startMinute": 0,
                              "duration": 33,
                              "entryPoint": 33
                          }
                      }
                  }
              },
              "reported": {
                  "isConnected": false,
                  "rssi": -83,
                  "batteryLevel": 90,
                  "operationState": "IDLE",
                  "operationSubState": "READY",
                  "operationSituation": "OPERATION_PERMITTED",
                  "operationError": {
                      "code": 999,
                      "type": "ISSUE",
                      "description": "UNKNOWN"
                  },
                  "operationIssue": {
                      "code": 0,
                      "class": 0,
                      "data": 0,
                      "flag": 0
                  },
                  "nextOperation": 0,
                  "remainingDuration": 0,
                  "rtc": "2023-11-30T11:00:00",
                  "languageSettings": {
                      "supported": [
                          "English",
                          "Deutsch",
                          "Italiano",
                          "Français",
                          "Español",
                          "Svenska",
                          "Norsk",
                          "Suomi",
                          "Dansk",
                          "Dutch"
                      ],
                      "selected": "Deutsch"
                  },
                  "chargingCurrent": 1000,
                  "tiltSlope": 20,
                  "entryPoints": [
                      1138,
                      284,
                      569,
                      854
                  ],
                  "rainSensor": true,
                  "rainDelay": 30,
                  "rainSensitivity": 8,
                  "frostSensor": true,
                  "frostDelay": 30,
                  "frostThreshold": 5,
                  "ecoMode": false,
                  "boundaryOverlap": 0,
                  "operationTimeTotal": 189891,
                  "operationTimeMowing": 471,
                  "operationTimeWheelMotorLeft": 152,
                  "operationTimeWheelMotorRight": 152,
                  "operationTimeBlade": 129,
                  "mowingCycles": 2542,
                  "chargingCycles": 2539,
                  "remainingBladeLifetime": 98,
                  "calibration": {
                      "loop": {
                          "slope": {
                              "l": 200,
                              "r": 204
                          },
                          "peak": {
                              "l": 1413,
                              "r": 1376
                          },
                          "length": 1138
                      },
                      "acc": {
                          "offset": {
                              "x": -85,
                              "y": 241
                          },
                          "security": {
                              "stopAngle": 20,
                              "orientation": 240
                          }
                      },
                      "hall": {
                          "zero": -2,
                          "trigger": 16,
                          "neutral": {
                              "x": -2,
                              "y": 1
                          },
                          "triggerLevel": 18,
                          "flag": 1
                      },
                      "qhk": {
                          "flag": false,
                          "left": 0,
                          "right": 0,
                          "peak": 0,
                          "upperBound": 0,
                          "lowerBound": 0
                      }
                  },
                  "batteryWeakVoltage": 3400,
                  "bladesService": 100,
                  "sideBumpTrigger": 18,
                  "restartLevel": 90,
                  "latSlopeCompUp": 12,
                  "latSlopeCompDown": 28,
                  "longDistanceTurn": 170,
                  "mowingStrategy": false,
                  "demoMode": false,
                  "marginMowing": false,
                  "bladeSpeed": 0,
                  "rpm": {
                      "set": 0,
                      "current": 0
                  },
                  "manualMarginMowing": false,
                  "offPitch": false,
                  "resetBladesService": false,
                  "qhk": {
                      "flag": true,
                      "angle": 90
                  },
                  "manualMowing": {
                      "activityMode": true,
                      "marginMode": false,
                      "narrowPassageMode": false,
                      "startHour": 17,
                      "startMinute": 9,
                      "duration": 15,
                      "entryPoint": 0
                  },
                  "ipaddress": "10.12.56.48",
                  "situationFlags": {
                      "operationPermitted": false,
                      "batteryWeak": false,
                      "batteryFailure": false,
                      "batteryWeakSticky": false,
                      "mowingWindow": false,
                      "marginMowingCompleted": false,
                      "userInteraction": false,
                      "loopSignalValid": false,
                      "chargerContact": true,
                      "chargerActive": false,
                      "chargerAllowsRestart": false,
                      "chargerFailure": false,
                      "manualOperation": false,
                      "robotIsActive": false,
                      "issueDisplay": false,
                      "homingTriggerSchedule": false,
                      "homingTriggerBatteryWaek": false,
                      "homingTriggerUserRequest": false,
                      "homingTriggerRain": false,
                      "homingTriggerFrost": false,
                      "homingTriggerTemperature": false,
                      "homingTriggerMowingUnitOverload": false,
                      "homingTriggerRestart": false,
                      "dayCancelled": false,
                      "rainDetected": false,
                      "rainAllowsMowing": true,
                      "frostDetected": false,
                      "frostAllowsMowing": true,
                      "temperatureMonitoringActive": true,
                      "temperatureAllowsMowing": true,
                      "temperatureAllowsHoming": true,
                      "temperatureAllowsCharging": true,
                      "wheelMotorTemperatureHigh": false,
                      "stopAfterIssue": false,
                      "bladeService": false,
                      "smarthomeActive": true
                  },
                  "mowingWindows": {
                      "monday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "tuesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "wednesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "thursday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "friday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "saturday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "sunday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": true,
                              "narrowPassageMode": true,
                              "startHour": 3,
                              "startMinute": 0,
                              "duration": 33,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": true,
                              "narrowPassageMode": true,
                              "startHour": 2,
                              "startMinute": 0,
                              "duration": 33,
                              "entryPoint": 33
                          }
                      }
                  },
                  "remainingDurationPercentage": 0
              },
              "delta": {
                  "mowingWindows": {
                      "thursday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": true,
                              "startHour": 14,
                              "startMinute": 15,
                              "duration": 130
                          },
                          "window_2": {
                              "startHour": 15
                          }
                      },
                      "sunday": {
                          "window_1": {
                              "activityMode": false
                          },
                          "window_2": {
                              "activityMode": false
                          }
                      }
                  }
              }
          }
      },
      "pimInformation": {
          "artnumber": "127695",
          "accessoryList": [
              {
                  "id": "3662",
                  "artnumber": "119461"
              },
              {
                  "id": "3663",
                  "artnumber": "119462"
              },
              {
                  "id": "84411",
                  "artnumber": "119990"
              },
              {
                  "id": "5341",
                  "artnumber": "127327"
              },
              {
                  "id": "5349",
                  "artnumber": "127335"
              },
              {
                  "id": "5469",
                  "artnumber": "127465"
              },
              {
                  "id": "5470",
                  "artnumber": "127466"
              },
              {
                  "id": "5515",
                  "artnumber": "127513"
              },
              {
                  "id": "95958",
                  "artnumber": "127675"
              },
              {
                  "id": "95968",
                  "artnumber": "127676"
              },
              {
                  "id": "162528",
                  "artnumber": "127699"
              },
              {
                  "id": "87067",
                  "artnumber": "534996"
              }
          ],
          "price": 0,
          "brand": [
              {
                  "id": "170",
                  "name": "solo® by AL-KO"
              }
          ],
          "additionalDocuments": [
              {
                  "doctype": "BED",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EDEA0D95A73B094F0ED",
                  "docname": "Manual_127695_02_495761.pdf",
                  "version": "02"
              },
              {
                  "doctype": "KONF",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EEDAA90D00FF5BB5C4E",
                  "docname": "Conf_127695_01_495762.pdf",
                  "version": "01"
              }
          ],
          "title": "Lawnmower Robolinho 520 W Solo By Al-ko",
          "productHierarchy": "G1050021",
          "crosssellingList": null,
          "attributeList": [
              {
                  "id": "93227",
                  "key": "GT_ECO_MODE_002",
                  "name": "GT_ECO_MODE",
                  "label": null,
                  "attrtype": "string",
                  "value_once": "No",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": "",
                  "parent": {
                      "id": "86669",
                      "name": "GT_ECO_MODE",
                      "label": "Device has eco mode",
                      "exportToShop": true,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "93228",
                  "key": "GT_KONNEKTIVITAET_003",
                  "name": "GT_KONNEKTIVITAET",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "AL-KO Connect WLAN",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": "",
                  "parent": {
                      "id": "334",
                      "name": "GT_KONNEKTIVITAET",
                      "label": "Connectivity",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "153178",
                  "key": "GT_MAEHSTRATEGIE_001",
                  "name": "GT_MAEHSTRATEGIE",
                  "label": null,
                  "attrtype": "string",
                  "value_once": "001",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": "",
                  "parent": {
                      "id": "95979",
                      "name": "GT_MAEHSTRATEGIE",
                      "label": "GT_MAEHSTRATEGIE",
                      "exportToShop": false,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "92683",
                  "key": "GT_REGENSENSOR_001",
                  "name": "GT_REGENSENSOR",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Yes",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": null,
                  "parent": {
                      "id": "392",
                      "name": "GT_REGENSENSOR",
                      "label": "Rain Sensor",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93229",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_002",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Amazon Alexa",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93230",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_003",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "IFTTT",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "90021",
                  "key": "GT_MARKE_002",
                  "name": "GT_MARKE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "solo® by AL-KO",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": "",
                  "parent": {
                      "id": "181",
                      "name": "GT_MARKE",
                      "label": "Brand",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92334",
                  "key": "GT_AKKU_INTERN_LADEZEIT_MIN_45_0",
                  "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 45,
                  "internalValue": "45_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "336",
                      "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                      "label": "Charging Time Internal Battery",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92663",
                  "key": "GT_AKKU_INTERN_LAUFZEIT_MIN_50_0",
                  "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 50,
                  "internalValue": "50_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "337",
                      "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                      "label": "Battery Run Time (internal)",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92665",
                  "key": "GT_EINSTIEGSPUNKTE_N_3_0",
                  "name": "GT_EINSTIEGSPUNKTE_N",
                  "label": null,
                  "attrtype": "int",
                  "value_once": null,
                  "value_to": 0,
                  "value_from": 3,
                  "internalValue": "3_0",
                  "unit": null,
                  "parent": {
                      "id": "391",
                      "name": "GT_EINSTIEGSPUNKTE_N",
                      "label": "Number of Entry Points",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "96069",
                  "key": "GT_GESCHW_VORWAERTS_MAX_KMH_0_97",
                  "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                  "label": null,
                  "attrtype": "float",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 0.97,
                  "internalValue": "0_97",
                  "unit": "KMH",
                  "parent": {
                      "id": "357",
                      "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                      "label": "Forward Speed max km/h",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92667",
                  "key": "GT_SCHNITTBREITE_CM_20_0",
                  "name": "GT_SCHNITTBREITE_CM",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 20,
                  "internalValue": "20_0",
                  "unit": "CM",
                  "parent": {
                      "id": "269",
                      "name": "GT_SCHNITTBREITE_CM",
                      "label": "Cutting Width in cm",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              }
          ],
          "appimages": [
              {
                  "image": {
                      "mimetype": "image/png",
                      "update": "1677737838",
                      "filename": "127695_sba_Robolinho_520_W_inTOUCH.png",
                      "fullpath": "https://pim.al-ko.com/products/inTouchApp/127695_sba_Robolinho_520_W_inTOUCH.png"
                  }
              }
          ],
          "images": null
      }
  },
  {
      "thingName": "DUMMY_Robolinho450",
      "thingType": "ALKO-ROBOLINHO",
      "thingAttributes": {
          "thingName": "DUMMY_Robolinho450",
          "thingType": "ALKO-ROBOLINHO",
          "articleNumber": "127618",
          "serialNumber": "UNKNOWN",
          "firmwareWifi": "1.6.5",
          "hardwareVersionWifi": "1D",
          "firmwareMain": "2101A-SW",
          "firmwareMainLocalization": "SW",
          "serialNumberMain": "serialNumberMain",
          "hardwareVersionMain": "1D",
          "thingModel": "Robolinho450",
          "firmwareWifiDriver": "V19",
          "serialNumberWifi": "01238A1DCC261843EE",
          "fotaMode": "once",
          "betatester": "False"
      },
      "accessInformation": {
          "accessId": "fb3c28b1-e411-43ef-8086-941ff8268cc7",
          "thingName": "DUMMY_Robolinho450",
          "userId": "0008020010",
          "userEmail": "thomas.echerer@al-ko.com",
          "accessAlias": "der testgerät",
          "accessAdmin": false,
          "accessCreated": "2021-06-30T07:59:08.809Z",
          "issuerId": "0008020010",
          "issuerEmail": "thomas.echerer@al-ko.com"
      },
      "accesses": [
          {
              "accessId": "fb3c28b1-e411-43ef-8086-941ff8268cc7",
              "thingName": "DUMMY_Robolinho450",
              "userId": "0008020010",
              "userEmail": "thomas.echerer@al-ko.com",
              "accessAlias": "der testgerät",
              "accessAdmin": false,
              "accessCreated": "2021-06-30T07:59:08.809Z",
              "issuerId": "0008020010",
              "issuerEmail": "thomas.echerer@al-ko.com"
          }
      ],
      "thingState": {
          "state": {
              "desired": {
                  "mowingWindows": {
                      "monday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 10,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 210,
                              "entryPoint": 0
                          }
                      },
                      "tuesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 6,
                              "startMinute": 30,
                              "duration": 90,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 210,
                              "entryPoint": 0
                          }
                      },
                      "wednesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 6,
                              "startMinute": 30,
                              "duration": 60,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 165,
                              "entryPoint": 0
                          }
                      },
                      "thursday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": true,
                              "narrowPassageMode": false,
                              "startHour": 9,
                              "startMinute": 30,
                              "duration": 1260,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 15,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "friday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 6,
                              "startMinute": 30,
                              "duration": 90,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 210,
                              "entryPoint": 0
                          }
                      },
                      "saturday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 6,
                              "startMinute": 30,
                              "duration": 90,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 210,
                              "entryPoint": 0
                          }
                      },
                      "sunday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 0,
                              "startMinute": 16,
                              "duration": 315,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 255,
                              "entryPoint": 33
                          }
                      }
                  }
              },
              "reported": {
                  "isConnected": true,
                  "rssi": -82,
                  "batteryLevel": 86,
                  "operationState": "IDLE",
                  "operationSubState": "READY",
                  "operationSituation": "OPERATION_PERMITTED",
                  "operationError": {
                      "code": 999,
                      "type": "ISSUE",
                      "description": "UNKNOWN"
                  },
                  "nextOperation": 3925,
                  "remainingDuration": 0,
                  "rtc": "2021-08-13T14:20:00",
                  "languageSettings": {
                      "selected": "Deutsch",
                      "supported": [
                          "English",
                          "Deutsch",
                          "Italiano",
                          "Français",
                          "Español",
                          "Svenska",
                          "Norsk",
                          "Suomi",
                          "Dansk",
                          "Dutch"
                      ]
                  },
                  "chargingCurrent": 1500,
                  "tiltSlope": 24,
                  "entryPoints": [
                      0,
                      975,
                      2615,
                      1200
                  ],
                  "rainSensor": true,
                  "rainDelay": 30,
                  "ecoMode": false,
                  "boundaryOverlap": 0,
                  "operationTimeTotal": 471017,
                  "operationTimeMowing": 17768,
                  "operationTimeWheelMotorLeft": 16867,
                  "operationTimeWheelMotorRight": 16866,
                  "operationTimeBlade": 16009,
                  "mowingCycles": 449,
                  "chargingCycles": 353,
                  "remainingBladeLifetime": 100,
                  "mowingWindows": {
                      "monday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 10,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 210,
                              "entryPoint": 0
                          }
                      },
                      "tuesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 6,
                              "startMinute": 30,
                              "duration": 90,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 210,
                              "entryPoint": 0
                          }
                      },
                      "wednesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 6,
                              "startMinute": 30,
                              "duration": 60,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 165,
                              "entryPoint": 0
                          }
                      },
                      "thursday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 6,
                              "startMinute": 30,
                              "duration": 90,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 210,
                              "entryPoint": 0
                          }
                      },
                      "friday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 6,
                              "startMinute": 30,
                              "duration": 90,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 210,
                              "entryPoint": 0
                          }
                      },
                      "saturday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 6,
                              "startMinute": 30,
                              "duration": 90,
                              "entryPoint": 0
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 210,
                              "entryPoint": 0
                          }
                      },
                      "sunday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 0,
                              "startMinute": 16,
                              "duration": 315,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "startHour": 15,
                              "startMinute": 45,
                              "duration": 255,
                              "entryPoint": 33
                          }
                      }
                  },
                  "situationFlags": {
                      "operationPermitted": false,
                      "batteryWeak": false,
                      "batteryFailure": false,
                      "batteryWeakSticky": false,
                      "mowingWindow": false,
                      "marginMowingCompleted": false,
                      "userInteraction": false,
                      "loopSignalValid": true,
                      "chargerContact": true,
                      "chargerActive": false,
                      "chargerAllowsRestart": false,
                      "chargerFailure": false,
                      "manualOperation": false,
                      "robotIsActive": false,
                      "issueDisplay": false,
                      "homingTriggerSchedule": false,
                      "homingTriggerBatteryWaek": false,
                      "homingTriggerUserRequest": false,
                      "homingTriggerRain": false,
                      "homingTriggerTemperature": false,
                      "homingTriggerMowingUnitOverload": false,
                      "homingTriggerRestart": false,
                      "dayCancelled": false,
                      "rainDetected": false,
                      "rainAllowsMowing": true,
                      "temperatureMonitoringActive": true,
                      "temperatureAllowsMowing": true,
                      "temperatureAllowsHoming": true,
                      "temperatureAllowsCharging": true,
                      "wheelMotorTemperatureHigh": false,
                      "stopAfterIssue": false,
                      "bladeService": true
                  },
                  "remainingDurationPercentage": 0
              },
              "delta": {
                  "mowingWindows": {
                      "thursday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": true,
                              "narrowPassageMode": false,
                              "startHour": 9,
                              "duration": 1260,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "narrowPassageMode": false,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      }
                  }
              }
          }
      },
      "pimInformation": {
          "artnumber": "127618",
          "accessoryList": [
              {
                  "id": "3662",
                  "artnumber": "119461"
              },
              {
                  "id": "3663",
                  "artnumber": "119462"
              },
              {
                  "id": "84411",
                  "artnumber": "119990"
              },
              {
                  "id": "5341",
                  "artnumber": "127327"
              },
              {
                  "id": "5349",
                  "artnumber": "127335"
              },
              {
                  "id": "5469",
                  "artnumber": "127465"
              },
              {
                  "id": "5470",
                  "artnumber": "127466"
              },
              {
                  "id": "5515",
                  "artnumber": "127513"
              },
              {
                  "id": "162528",
                  "artnumber": "127699"
              }
          ],
          "price": 0,
          "brand": [
              {
                  "id": "170",
                  "name": "solo® by AL-KO"
              }
          ],
          "additionalDocuments": [
              {
                  "doctype": "BED",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EDCA3FBDDC5FA5FF353",
                  "docname": "Manual_127618_06_495439.pdf",
                  "version": "06"
              },
              {
                  "doctype": "KONF",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EDCA3FBC5841D77128E",
                  "docname": "Conf_127618_04_442812.pdf",
                  "version": "04"
              }
          ],
          "title": "Lawnmower Robolinho 450 W Solo By Al-ko",
          "productHierarchy": "G1050021",
          "crosssellingList": [
              {
                  "artnumber": "127465",
                  "id": "5469"
              },
              {
                  "artnumber": "127513",
                  "id": "5515"
              }
          ],
          "attributeList": [
              {
                  "id": "93227",
                  "key": "GT_ECO_MODE_002",
                  "name": "GT_ECO_MODE",
                  "label": null,
                  "attrtype": "string",
                  "value_once": "No",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": "",
                  "parent": {
                      "id": "86669",
                      "name": "GT_ECO_MODE",
                      "label": "Device has eco mode",
                      "exportToShop": true,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "93228",
                  "key": "GT_KONNEKTIVITAET_003",
                  "name": "GT_KONNEKTIVITAET",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "AL-KO Connect WLAN",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": "",
                  "parent": {
                      "id": "334",
                      "name": "GT_KONNEKTIVITAET",
                      "label": "Connectivity",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "153178",
                  "key": "GT_MAEHSTRATEGIE_001",
                  "name": "GT_MAEHSTRATEGIE",
                  "label": null,
                  "attrtype": "string",
                  "value_once": "001",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": "",
                  "parent": {
                      "id": "95979",
                      "name": "GT_MAEHSTRATEGIE",
                      "label": "GT_MAEHSTRATEGIE",
                      "exportToShop": false,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "92683",
                  "key": "GT_REGENSENSOR_001",
                  "name": "GT_REGENSENSOR",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Yes",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": null,
                  "parent": {
                      "id": "392",
                      "name": "GT_REGENSENSOR",
                      "label": "Rain Sensor",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93229",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_002",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Amazon Alexa",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "162630",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_004",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "004",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "004",
                  "unit": "",
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93230",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_003",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "IFTTT",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "90021",
                  "key": "GT_MARKE_002",
                  "name": "GT_MARKE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "solo® by AL-KO",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": "",
                  "parent": {
                      "id": "181",
                      "name": "GT_MARKE",
                      "label": "Brand",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92334",
                  "key": "GT_AKKU_INTERN_LADEZEIT_MIN_45_0",
                  "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 45,
                  "internalValue": "45_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "336",
                      "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                      "label": "Charging Time Internal Battery",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92663",
                  "key": "GT_AKKU_INTERN_LAUFZEIT_MIN_50_0",
                  "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 50,
                  "internalValue": "50_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "337",
                      "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                      "label": "Battery Run Time (internal)",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92665",
                  "key": "GT_EINSTIEGSPUNKTE_N_3_0",
                  "name": "GT_EINSTIEGSPUNKTE_N",
                  "label": null,
                  "attrtype": "int",
                  "value_once": null,
                  "value_to": 0,
                  "value_from": 3,
                  "internalValue": "3_0",
                  "unit": null,
                  "parent": {
                      "id": "391",
                      "name": "GT_EINSTIEGSPUNKTE_N",
                      "label": "Number of Entry Points",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "96069",
                  "key": "GT_GESCHW_VORWAERTS_MAX_KMH_0_97",
                  "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                  "label": null,
                  "attrtype": "float",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 0.97,
                  "internalValue": "0_97",
                  "unit": "KMH",
                  "parent": {
                      "id": "357",
                      "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                      "label": "Forward Speed max km/h",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92667",
                  "key": "GT_SCHNITTBREITE_CM_20_0",
                  "name": "GT_SCHNITTBREITE_CM",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 20,
                  "internalValue": "20_0",
                  "unit": "CM",
                  "parent": {
                      "id": "269",
                      "name": "GT_SCHNITTBREITE_CM",
                      "label": "Cutting Width in cm",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              }
          ],
          "appimages": [
              {
                  "image": {
                      "mimetype": "image/png",
                      "update": "1634129381",
                      "filename": "127618_cut_out.png",
                      "fullpath": "https://pim.al-ko.com/products/inTouchApp/127618_cut_out.png"
                  }
              }
          ],
          "images": [
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1681825571",
                      "filename": "127618_Robotic_Lawnmower_Robolinho_450_W_Smart_Cloud_Icons.jpg",
                      "fullpath": "https://pim.al-ko.com/products/images/127618_Robotic_Lawnmower_Robolinho_450_W_Smart_Cloud_Icons.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1675934342",
                      "filename": "Engineered_in_Germany_Mood_2022_neu.jpg",
                      "fullpath": "https://pim.al-ko.com/products/images/Engineered_in_Germany_Mood_2022_neu.jpg"
                  }
              }
          ]
      }
  },
  {
      "thingName": "c27ca6b50dfca1c0e47b26eaaa0212877f4dc2aa",
      "thingType": "ALKO-ROBOLINHO",
      "thingAttributes": {
          "thingName": "c27ca6b50dfca1c0e47b26eaaa0212877f4dc2aa",
          "thingType": "ALKO-ROBOLINHO",
          "articleNumber": "127570",
          "serialNumber": "TEST-010001",
          "firmwareWifi": "1.7.5",
          "hardwareVersionWifi": "1D",
          "firmwareMain": "2301A-SW",
          "firmwareMainLocalization": "SW",
          "serialNumberMain": "TEST-010001",
          "hardwareVersionMain": "1D",
          "thingModel": "Robolinho1200",
          "firmwareWifiDriver": "V19.6.1",
          "serialNumberWifi": "0123B98752FE9A00EE",
          "fotaMode": "once",
          "betatester": "False"
      },
      "accessInformation": {
          "accessId": "CC73ADF6-43AF-4835-BC0D-40501D9171A0",
          "thingName": "c27ca6b50dfca1c0e47b26eaaa0212877f4dc2aa",
          "userId": "0008020010",
          "userEmail": "thomas.echerer@al-ko.com",
          "accessAlias": "Gandalf",
          "accessAdmin": true,
          "accessCreated": "2023-03-01T09:31:41.010Z"
      },
      "accesses": [
          {
              "accessId": "CC73ADF6-43AF-4835-BC0D-40501D9171A0",
              "thingName": "c27ca6b50dfca1c0e47b26eaaa0212877f4dc2aa",
              "userId": "0008020010",
              "userEmail": "thomas.echerer@al-ko.com",
              "accessAlias": "Gandalf",
              "accessAdmin": true,
              "accessCreated": "2023-03-01T09:31:41.010Z"
          },
          {
              "accessId": "8ec01637-2cad-4ff1-a928-f4e535fccd5e",
              "thingName": "c27ca6b50dfca1c0e47b26eaaa0212877f4dc2aa",
              "userId": "0008040685",
              "userEmail": "juergen.dietrich@al-ko.com",
              "accessAlias": "indoorstrecke_rechts",
              "accessAdmin": false,
              "accessCreated": "2022-11-09T08:37:49.855Z",
              "issuerId": "0008020010",
              "issuerEmail": "thomas.echerer@al-ko.com"
          },
          {
              "accessId": "8ec01637-2cad-4ff1-a928-f4e535fske5e",
              "thingName": "c27ca6b50dfca1c0e47b26eaaa0212877f4dc2aa",
              "userId": "0008043199",
              "userEmail": "peter.mueller@al-ko.com",
              "accessAlias": "indoorstrecke_rechts",
              "accessAdmin": false,
              "accessCreated": "2023-09-02T15:57:49.855Z",
              "issuerId": "0008020010",
              "issuerEmail": "thomas.echerer@al-ko.com"
          },
          {
              "accessId": "61411056-3c00-4761-96af-ba3144928f8b",
              "thingName": "c27ca6b50dfca1c0e47b26eaaa0212877f4dc2aa",
              "userId": "0008068307",
              "userEmail": "ivan.azenic@cobeisfresh.com",
              "accessAlias": "AL-KO Laboratory Robolinho",
              "accessAdmin": false,
              "accessCreated": "2022-07-13T07:59:28.173Z",
              "issuerId": "0008020006",
              "issuerEmail": "christian.kranemann@al-ko.com"
          },
          {
              "accessId": "31efbfc5-81d3-48fb-9787-b2ab2d8af6af",
              "thingName": "c27ca6b50dfca1c0e47b26eaaa0212877f4dc2aa",
              "userId": "0008086140",
              "userEmail": "philipp.rehm@al-ko.com",
              "accessAlias": "AL-KO Laboratory Robinhood ",
              "accessAdmin": false,
              "accessCreated": "2022-09-13T10:35:44.900Z",
              "issuerId": "0008020006",
              "issuerEmail": "christian.kranemann@al-ko.com"
          }
      ],
      "thingState": {
          "state": {
              "desired": {
                  "mowingWindows": {
                      "monday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      },
                      "tuesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      },
                      "wednesday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 9,
                              "startMinute": 45,
                              "duration": 540,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 18,
                              "startMinute": 45,
                              "duration": 0,
                              "entryPoint": 32
                          }
                      },
                      "thursday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      },
                      "friday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": true,
                              "narrowPassageMode": false,
                              "startHour": 9,
                              "startMinute": 10,
                              "duration": 35,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 15,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "saturday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      },
                      "sunday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      }
                  }
              },
              "reported": {
                  "isConnected": false,
                  "rssi": -63,
                  "batteryLevel": 100,
                  "operationState": "IDLE",
                  "operationSubState": "LOCKED_PIN",
                  "operationSituation": "OPERATION_NOT_PERMITTED_LOCKED",
                  "operationError": {
                      "code": 999,
                      "type": "ISSUE",
                      "description": "UNKNOWN"
                  },
                  "operationIssue": {
                      "code": 0,
                      "class": 0,
                      "data": 0,
                      "flag": 0
                  },
                  "nextOperation": 0,
                  "remainingDuration": 427,
                  "rtc": "2023-03-01T11:39:00",
                  "languageSettings": {
                      "supported": [
                          "English",
                          "Deutsch",
                          "Italiano",
                          "Français",
                          "Español",
                          "Svenska",
                          "Norsk",
                          "Suomi",
                          "Dansk",
                          "Dutch"
                      ],
                      "selected": "Deutsch"
                  },
                  "chargingCurrent": 1500,
                  "tiltSlope": 24,
                  "entryPoints": [
                      0,
                      5000,
                      10000,
                      15000,
                      20000,
                      25000,
                      30000
                  ],
                  "rainSensor": true,
                  "rainDelay": 30,
                  "rainSensitivity": 8,
                  "frostSensor": true,
                  "frostDelay": 30,
                  "frostThreshold": 5,
                  "ecoMode": true,
                  "boundaryOverlap": 0,
                  "operationTimeTotal": 1343757,
                  "operationTimeMowing": 462258,
                  "operationTimeWheelMotorLeft": 485684,
                  "operationTimeWheelMotorRight": 485686,
                  "operationTimeBlade": 383299,
                  "mowingCycles": 47286,
                  "chargingCycles": 45992,
                  "remainingBladeLifetime": 98,
                  "calibration": {
                      "loop": {
                          "slope": {
                              "l": 108,
                              "r": 105
                          },
                          "peak": {
                              "l": 2671,
                              "r": 2677
                          },
                          "length": 0
                      },
                      "acc": {
                          "offset": {
                              "x": -798,
                              "y": 886
                          },
                          "security": {
                              "stopAngel": 24,
                              "orientation": 240
                          }
                      },
                      "hall": {
                          "zero": -4,
                          "trigger": 17,
                          "neutral": {
                              "x": -4,
                              "y": 5
                          },
                          "triggerLevel": 21,
                          "flag": 1
                      },
                      "qhk": {
                          "flag": false,
                          "left": 0,
                          "right": 0,
                          "peak": 0,
                          "upperBound": 0,
                          "lowerBound": 0
                      }
                  },
                  "batteryWeakVoltage": 3400,
                  "bladesService": 100,
                  "sideBumpTrigger": 21,
                  "restartLevel": 90,
                  "latSlopeCompUp": 12,
                  "latSlopeCompDown": 28,
                  "longDistanceTurn": 130,
                  "mowingStrategy": true,
                  "demoMode": false,
                  "marginMowing": false,
                  "manualMarginMowing": false,
                  "offPitch": false,
                  "resetBladesService": false,
                  "qhk": {
                      "flag": false,
                      "angle": 45
                  },
                  "manualMowing": {
                      "activityMode": false,
                      "marginMode": false,
                      "narrowPassageMode": false,
                      "startHour": 0,
                      "startMinute": 0,
                      "duration": 0,
                      "entryPoint": 35
                  },
                  "situationFlags": {
                      "operationPermitted": false,
                      "batteryWeak": false,
                      "batteryFailure": false,
                      "batteryWeakSticky": false,
                      "mowingWindow": true,
                      "marginMowingCompleted": false,
                      "userInteraction": true,
                      "loopSignalValid": true,
                      "chargerContact": false,
                      "chargerActive": false,
                      "chargerAllowsRestart": false,
                      "chargerFailure": false,
                      "manualOperation": false,
                      "robotIsActive": false,
                      "issueDisplay": false,
                      "homingTriggerSchedule": false,
                      "homingTriggerBatteryWaek": false,
                      "homingTriggerUserRequest": false,
                      "homingTriggerRain": false,
                      "homingTriggerFrost": false,
                      "homingTriggerTemperature": false,
                      "homingTriggerMowingUnitOverload": false,
                      "homingTriggerRestart": false,
                      "dayCancelled": false,
                      "rainDetected": false,
                      "rainAllowsMowing": true,
                      "frostDetected": false,
                      "frostAllowsMowing": true,
                      "temperatureMonitoringActive": true,
                      "temperatureAllowsMowing": true,
                      "temperatureAllowsHoming": true,
                      "temperatureAllowsCharging": true,
                      "wheelMotorTemperatureHigh": false,
                      "stopAfterIssue": false,
                      "bladeService": false,
                      "smarthomeActive": true
                  },
                  "mowingWindows": {
                      "monday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      },
                      "tuesday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      },
                      "wednesday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 9,
                              "startMinute": 45,
                              "duration": 540,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 18,
                              "startMinute": 45,
                              "duration": 0,
                              "entryPoint": 32
                          }
                      },
                      "thursday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      },
                      "friday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 30,
                              "duration": 195,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      },
                      "saturday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      },
                      "sunday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 10,
                              "startMinute": 30,
                              "duration": 15,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 11,
                              "startMinute": 0,
                              "duration": 15,
                              "entryPoint": 32
                          }
                      }
                  },
                  "remainingDurationPercentage": 79
              },
              "delta": {
                  "mowingWindows": {
                      "monday": {
                          "window_1": {
                              "activityMode": false
                          },
                          "window_2": {
                              "activityMode": false
                          }
                      },
                      "tuesday": {
                          "window_1": {
                              "activityMode": false
                          },
                          "window_2": {
                              "activityMode": false
                          }
                      },
                      "wednesday": {
                          "window_1": {
                              "activityMode": false
                          }
                      },
                      "thursday": {
                          "window_1": {
                              "activityMode": false
                          },
                          "window_2": {
                              "activityMode": false
                          }
                      },
                      "friday": {
                          "window_1": {
                              "marginMode": true,
                              "startHour": 9,
                              "startMinute": 10,
                              "duration": 35,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": false,
                              "startHour": 15,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "saturday": {
                          "window_1": {
                              "activityMode": false
                          },
                          "window_2": {
                              "activityMode": false
                          }
                      },
                      "sunday": {
                          "window_1": {
                              "activityMode": false
                          },
                          "window_2": {
                              "activityMode": false
                          }
                      }
                  }
              }
          }
      },
      "pimInformation": {
          "artnumber": "127570",
          "accessoryList": [
              {
                  "id": "3662",
                  "artnumber": "119461"
              },
              {
                  "id": "3663",
                  "artnumber": "119462"
              },
              {
                  "id": "84411",
                  "artnumber": "119990"
              },
              {
                  "id": "5341",
                  "artnumber": "127327"
              },
              {
                  "id": "5349",
                  "artnumber": "127335"
              },
              {
                  "id": "5469",
                  "artnumber": "127465"
              },
              {
                  "id": "5515",
                  "artnumber": "127513"
              },
              {
                  "id": "5516",
                  "artnumber": "127514"
              },
              {
                  "id": "5518",
                  "artnumber": "127516"
              },
              {
                  "id": "5539",
                  "artnumber": "127544"
              },
              {
                  "id": "162528",
                  "artnumber": "127699"
              }
          ],
          "price": 0,
          "brand": [
              {
                  "id": "170",
                  "name": "solo® by AL-KO"
              }
          ],
          "additionalDocuments": [
              {
                  "doctype": "BED",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EDCA3FBDDC5FA5FF353",
                  "docname": "Manual_127570_06_495439.pdf",
                  "version": "06"
              },
              {
                  "doctype": "KONF",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EDCA3FBC5841D77128E",
                  "docname": "Conf_127570_04_442812.pdf",
                  "version": "04"
              }
          ],
          "title": "Lawnmower Robolinho 1200 W Solo By Al-ko",
          "productHierarchy": "G1050021",
          "crosssellingList": [
              {
                  "artnumber": "127465",
                  "id": "5469"
              },
              {
                  "artnumber": "127513",
                  "id": "5515"
              }
          ],
          "attributeList": [
              {
                  "id": "93235",
                  "key": "GT_ECO_MODE_001",
                  "name": "GT_ECO_MODE",
                  "label": "The device has the eco mode.",
                  "attrtype": "string",
                  "value_once": "Yes",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": "",
                  "parent": {
                      "id": "86669",
                      "name": "GT_ECO_MODE",
                      "label": "Device has eco mode",
                      "exportToShop": true,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "93228",
                  "key": "GT_KONNEKTIVITAET_003",
                  "name": "GT_KONNEKTIVITAET",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "AL-KO Connect WLAN",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": "",
                  "parent": {
                      "id": "334",
                      "name": "GT_KONNEKTIVITAET",
                      "label": "Connectivity",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "153178",
                  "key": "GT_MAEHSTRATEGIE_001",
                  "name": "GT_MAEHSTRATEGIE",
                  "label": null,
                  "attrtype": "string",
                  "value_once": "001",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": "",
                  "parent": {
                      "id": "95979",
                      "name": "GT_MAEHSTRATEGIE",
                      "label": "GT_MAEHSTRATEGIE",
                      "exportToShop": false,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "92683",
                  "key": "GT_REGENSENSOR_001",
                  "name": "GT_REGENSENSOR",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Yes",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": null,
                  "parent": {
                      "id": "392",
                      "name": "GT_REGENSENSOR",
                      "label": "Rain Sensor",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93229",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_002",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Amazon Alexa",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "162630",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_004",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "004",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "004",
                  "unit": "",
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93230",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_003",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "IFTTT",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "90021",
                  "key": "GT_MARKE_002",
                  "name": "GT_MARKE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "solo® by AL-KO",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": "",
                  "parent": {
                      "id": "181",
                      "name": "GT_MARKE",
                      "label": "Brand",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "90028",
                  "key": "GT_PRODUKTLINIE_003",
                  "name": "GT_PRODUKTLINIE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Premium",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": "",
                  "parent": {
                      "id": "184",
                      "name": "GT_PRODUKTLINIE",
                      "label": "Product Range",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92383",
                  "key": "GT_AKKU_INTERN_LADEZEIT_MIN_90_0",
                  "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 90,
                  "internalValue": "90_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "336",
                      "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                      "label": "Charging Time Internal Battery",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93232",
                  "key": "GT_AKKU_INTERN_LAUFZEIT_MIN_140_0",
                  "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 140,
                  "internalValue": "140_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "337",
                      "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                      "label": "Battery Run Time (internal)",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93058",
                  "key": "GT_EINSTIEGSPUNKTE_N_6_0",
                  "name": "GT_EINSTIEGSPUNKTE_N",
                  "label": null,
                  "attrtype": "int",
                  "value_once": null,
                  "value_to": 0,
                  "value_from": 6,
                  "internalValue": "6_0",
                  "unit": null,
                  "parent": {
                      "id": "391",
                      "name": "GT_EINSTIEGSPUNKTE_N",
                      "label": "Number of Entry Points",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "96069",
                  "key": "GT_GESCHW_VORWAERTS_MAX_KMH_0_97",
                  "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                  "label": null,
                  "attrtype": "float",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 0.97,
                  "internalValue": "0_97",
                  "unit": "KMH",
                  "parent": {
                      "id": "357",
                      "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                      "label": "Forward Speed max km/h",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93059",
                  "key": "GT_SCHNITTBREITE_CM_23_0",
                  "name": "GT_SCHNITTBREITE_CM",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 23,
                  "internalValue": "23_0",
                  "unit": "CM",
                  "parent": {
                      "id": "269",
                      "name": "GT_SCHNITTBREITE_CM",
                      "label": "Cutting Width in cm",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              }
          ],
          "appimages": [
              {
                  "image": {
                      "mimetype": "image/png",
                      "update": "1611305024",
                      "filename": "127570_sba_robolinho_1200_w.png",
                      "fullpath": "https://pim.al-ko.com/products/inTouchApp/127570_sba_robolinho_1200_w.png"
                  }
              }
          ],
          "images": [
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1624537165",
                      "filename": "127570_sba_robo_1200_w.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/127570_sba_robo_1200_w.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1624537168",
                      "filename": "sba_robo_image_026.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/sba_robo_image_026.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1624537168",
                      "filename": "sba_chrisanne_robo_moodrelax_020.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/sba_chrisanne_robo_moodrelax_020.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1675934342",
                      "filename": "Engineered_in_Germany_Mood_2022_neu.jpg",
                      "fullpath": "https://pim.al-ko.com/products/images/Engineered_in_Germany_Mood_2022_neu.jpg"
                  }
              }
          ]
      }
  },
  {
      "thingName": "fec906c7545ab3da207a43d121d9bff3470d403e",
      "thingType": "ALKO-ROBOLINHO",
      "thingAttributes": {
          "thingName": "fec906c7545ab3da207a43d121d9bff3470d403e",
          "thingType": "ALKO-ROBOLINHO",
          "articleNumber": "127569",
          "serialNumber": "8A31-002617",
          "firmwareWifi": "1.9.6",
          "hardwareVersionWifi": "UNKNOWN",
          "firmwareMain": "2304A-SW",
          "firmwareMainLocalization": "SW",
          "serialNumberMain": "8A31-002617",
          "hardwareVersionMain": "UNKNOWN",
          "thingModel": "Robolinho700",
          "firmwareWifiDriver": "UNKNOWN",
          "serialNumberWifi": "0123FE34AAB269AEEE",
          "fotaMode": "always",
          "betatester": "True"
      },
      "accessInformation": {
          "accessId": "82938029-6c9f-4b80-a9b9-3d3a2a9e4176",
          "thingName": "fec906c7545ab3da207a43d121d9bff3470d403e",
          "userId": "0008020010",
          "userEmail": "thomas.echerer@al-ko.com",
          "accessAlias": "Frankenstein ",
          "accessAdmin": true,
          "accessCreated": "2023-08-14T07:14:08.186Z"
      },
      "accesses": [
          {
              "accessId": "82938029-6c9f-4b80-a9b9-3d3a2a9e4176",
              "thingName": "fec906c7545ab3da207a43d121d9bff3470d403e",
              "userId": "0008020010",
              "userEmail": "thomas.echerer@al-ko.com",
              "accessAlias": "Frankenstein ",
              "accessAdmin": true,
              "accessCreated": "2023-08-14T07:14:08.186Z"
          }
      ],
      "thingState": {
          "state": {
              "desired": {},
              "reported": {
                  "isConnected": false,
                  "rssi": -74,
                  "batteryLevel": 0,
                  "operationState": "CHARGING",
                  "operationSubState": "READY",
                  "operationSituation": "OPERATION_NOT_PERMITTED_WEAK_BATTERY",
                  "operationError": {
                      "code": 999,
                      "type": "ISSUE",
                      "description": "UNKNOWN"
                  },
                  "nextOperation": 2782,
                  "remainingDuration": 0,
                  "rtc": "2023-09-04T13:00:00",
                  "languageSettings": {
                      "selected": "English",
                      "supported": [
                          "English",
                          "Deutsch",
                          "Italiano",
                          "Français",
                          "Español",
                          "Svenska",
                          "Norsk",
                          "Suomi",
                          "Dansk",
                          "Dutch"
                      ]
                  },
                  "chargingCurrent": 1500,
                  "tiltSlope": 21,
                  "entryPoints": [
                      2732,
                      527,
                      1339,
                      2080
                  ],
                  "rainSensor": true,
                  "rainDelay": 30,
                  "rainSensitivity": 8,
                  "frostSensor": true,
                  "frostDelay": 30,
                  "frostThreshold": 5,
                  "ecoMode": true,
                  "boundaryOverlap": 11,
                  "operationTimeTotal": 360504,
                  "operationTimeMowing": 6287,
                  "operationTimeWheelMotorLeft": 2445,
                  "operationTimeWheelMotorRight": 2441,
                  "operationTimeBlade": 4184,
                  "mowingCycles": 10263,
                  "chargingCycles": 582,
                  "remainingBladeLifetime": 72,
                  "calibration": {
                      "loop": {
                          "slope": {
                              "l": 84,
                              "r": 91
                          },
                          "peak": {
                              "l": 3888,
                              "r": 3775
                          },
                          "length": 2732
                      },
                      "acc": {
                          "offset": {
                              "x": 95,
                              "y": 388
                          },
                          "security": {
                              "stopAngle": 21,
                              "orientation": 225
                          }
                      },
                      "hall": {
                          "zero": -1,
                          "trigger": 23,
                          "neutral": {
                              "x": -1,
                              "y": 0
                          },
                          "triggerLevel": 24,
                          "flag": 1
                      },
                      "qhk": {
                          "flag": false,
                          "left": 0,
                          "right": 0,
                          "peak": 0,
                          "upperBound": 0,
                          "lowerBound": 0
                      }
                  },
                  "batteryWeakVoltage": 3420,
                  "bladesService": 100,
                  "sideBumpTrigger": 24,
                  "restartLevel": 90,
                  "latSlopeCompUp": 12,
                  "latSlopeCompDown": 28,
                  "longDistanceTurn": 170,
                  "mowingStrategy": false,
                  "demoMode": false,
                  "marginMowing": false,
                  "bladeSpeed": 0,
                  "rpm": {
                      "set": 0,
                      "current": 0
                  },
                  "manualMarginMowing": false,
                  "offPitch": false,
                  "resetBladesService": false,
                  "qhk": {
                      "flag": true,
                      "angle": 90
                  },
                  "manualMowing": {
                      "activityMode": false,
                      "marginMode": false,
                      "narrowPassageMode": false,
                      "startHour": 0,
                      "startMinute": 0,
                      "duration": 0,
                      "entryPoint": 34
                  },
                  "ipaddress": "192.168.178.30",
                  "situationFlags": {
                      "operationPermitted": true,
                      "batteryWeak": false,
                      "batteryFailure": false,
                      "batteryWeakSticky": true,
                      "mowingWindow": false,
                      "marginMowingCompleted": false,
                      "userInteraction": true,
                      "loopSignalValid": false,
                      "chargerContact": true,
                      "chargerActive": true,
                      "chargerAllowsRestart": false,
                      "chargerFailure": false,
                      "manualOperation": false,
                      "robotIsActive": false,
                      "issueDisplay": false,
                      "homingTriggerSchedule": false,
                      "homingTriggerBatteryWaek": false,
                      "homingTriggerUserRequest": false,
                      "homingTriggerRain": false,
                      "homingTriggerFrost": false,
                      "homingTriggerTemperature": false,
                      "homingTriggerMowingUnitOverload": false,
                      "homingTriggerRestart": false,
                      "dayCancelled": false,
                      "rainDetected": false,
                      "rainAllowsMowing": true,
                      "frostDetected": false,
                      "frostAllowsMowing": true,
                      "temperatureMonitoringActive": true,
                      "temperatureAllowsMowing": true,
                      "temperatureAllowsHoming": true,
                      "temperatureAllowsCharging": true,
                      "wheelMotorTemperatureHigh": false,
                      "stopAfterIssue": false,
                      "bladeService": false,
                      "smarthomeActive": true
                  },
                  "mowingWindows": {
                      "monday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": true,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "tuesday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "wednesday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "thursday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "friday": {
                          "window_1": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 7,
                              "startMinute": 0,
                              "duration": 180,
                              "entryPoint": 33
                          },
                          "window_2": {
                              "activityMode": true,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 17,
                              "startMinute": 0,
                              "duration": 120,
                              "entryPoint": 33
                          }
                      },
                      "saturday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 0,
                              "startMinute": 0,
                              "duration": 0,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 0,
                              "startMinute": 0,
                              "duration": 0,
                              "entryPoint": 32
                          }
                      },
                      "sunday": {
                          "window_1": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 0,
                              "startMinute": 0,
                              "duration": 0,
                              "entryPoint": 32
                          },
                          "window_2": {
                              "activityMode": false,
                              "marginMode": false,
                              "narrowPassageMode": false,
                              "startHour": 0,
                              "startMinute": 0,
                              "duration": 0,
                              "entryPoint": 32
                          }
                      }
                  },
                  "remainingDurationPercentage": 0
              },
              "delta": {}
          }
      },
      "pimInformation": {
          "artnumber": "127569",
          "accessoryList": [
              {
                  "id": "3662",
                  "artnumber": "119461"
              },
              {
                  "id": "3663",
                  "artnumber": "119462"
              },
              {
                  "id": "84411",
                  "artnumber": "119990"
              },
              {
                  "id": "5341",
                  "artnumber": "127327"
              },
              {
                  "id": "5349",
                  "artnumber": "127335"
              },
              {
                  "id": "5469",
                  "artnumber": "127465"
              },
              {
                  "id": "5471",
                  "artnumber": "127467"
              },
              {
                  "id": "5515",
                  "artnumber": "127513"
              },
              {
                  "id": "5516",
                  "artnumber": "127514"
              },
              {
                  "id": "5518",
                  "artnumber": "127516"
              },
              {
                  "id": "162528",
                  "artnumber": "127699"
              }
          ],
          "price": 0,
          "brand": [
              {
                  "id": "170",
                  "name": "solo® by AL-KO"
              }
          ],
          "additionalDocuments": [
              {
                  "doctype": "BED",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EDCA3FBDDC5FA5FF353",
                  "docname": "Manual_127569_06_495439.pdf",
                  "version": "06"
              },
              {
                  "doctype": "KONF",
                  "docstorecat": "Z_A03_KPRO",
                  "docid": "005056BC0DE91EDCA3FBC5841D77128E",
                  "docname": "Conf_127569_04_442812.pdf",
                  "version": "04"
              }
          ],
          "title": "Lawnmower Robolinho 700 W Solo By Al-ko",
          "productHierarchy": "G1050021",
          "crosssellingList": [
              {
                  "artnumber": "127465",
                  "id": "5469"
              },
              {
                  "artnumber": "127513",
                  "id": "5515"
              }
          ],
          "attributeList": [
              {
                  "id": "93235",
                  "key": "GT_ECO_MODE_001",
                  "name": "GT_ECO_MODE",
                  "label": "The device has the eco mode.",
                  "attrtype": "string",
                  "value_once": "Yes",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": "",
                  "parent": {
                      "id": "86669",
                      "name": "GT_ECO_MODE",
                      "label": "Device has eco mode",
                      "exportToShop": true,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "93228",
                  "key": "GT_KONNEKTIVITAET_003",
                  "name": "GT_KONNEKTIVITAET",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "AL-KO Connect WLAN",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": "",
                  "parent": {
                      "id": "334",
                      "name": "GT_KONNEKTIVITAET",
                      "label": "Connectivity",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "153178",
                  "key": "GT_MAEHSTRATEGIE_001",
                  "name": "GT_MAEHSTRATEGIE",
                  "label": null,
                  "attrtype": "string",
                  "value_once": "001",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": "",
                  "parent": {
                      "id": "95979",
                      "name": "GT_MAEHSTRATEGIE",
                      "label": "GT_MAEHSTRATEGIE",
                      "exportToShop": false,
                      "exportToChannable": false
                  }
              },
              {
                  "id": "92683",
                  "key": "GT_REGENSENSOR_001",
                  "name": "GT_REGENSENSOR",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Yes",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "001",
                  "unit": null,
                  "parent": {
                      "id": "392",
                      "name": "GT_REGENSENSOR",
                      "label": "Rain Sensor",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93229",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_002",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Amazon Alexa",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "162630",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_004",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "004",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "004",
                  "unit": "",
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93230",
                  "key": "GT_SMART_PARTNER_INTEGRATIONEN_003",
                  "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "IFTTT",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": null,
                  "parent": {
                      "id": "86680",
                      "name": "GT_SMART_PARTNER_INTEGRATIONEN",
                      "label": "Works with partner system",
                      "exportToShop": false,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "90021",
                  "key": "GT_MARKE_002",
                  "name": "GT_MARKE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "solo® by AL-KO",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "002",
                  "unit": "",
                  "parent": {
                      "id": "181",
                      "name": "GT_MARKE",
                      "label": "Brand",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "90028",
                  "key": "GT_PRODUKTLINIE_003",
                  "name": "GT_PRODUKTLINIE",
                  "label": null,
                  "attrtype": "enum",
                  "value_once": "Premium",
                  "value_to": 0,
                  "value_from": 0,
                  "internalValue": "003",
                  "unit": "",
                  "parent": {
                      "id": "184",
                      "name": "GT_PRODUKTLINIE",
                      "label": "Product Range",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "93104",
                  "key": "GT_AKKU_INTERN_LADEZEIT_MIN_50_0",
                  "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 50,
                  "internalValue": "50_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "336",
                      "name": "GT_AKKU_INTERN_LADEZEIT_MIN",
                      "label": "Charging Time Internal Battery",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92678",
                  "key": "GT_AKKU_INTERN_LAUFZEIT_MIN_60_0",
                  "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 60,
                  "internalValue": "60_0",
                  "unit": "MIN",
                  "parent": {
                      "id": "337",
                      "name": "GT_AKKU_INTERN_LAUFZEIT_MIN",
                      "label": "Battery Run Time (internal)",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92665",
                  "key": "GT_EINSTIEGSPUNKTE_N_3_0",
                  "name": "GT_EINSTIEGSPUNKTE_N",
                  "label": null,
                  "attrtype": "int",
                  "value_once": null,
                  "value_to": 0,
                  "value_from": 3,
                  "internalValue": "3_0",
                  "unit": null,
                  "parent": {
                      "id": "391",
                      "name": "GT_EINSTIEGSPUNKTE_N",
                      "label": "Number of Entry Points",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "96069",
                  "key": "GT_GESCHW_VORWAERTS_MAX_KMH_0_97",
                  "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                  "label": null,
                  "attrtype": "float",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 0.97,
                  "internalValue": "0_97",
                  "unit": "KMH",
                  "parent": {
                      "id": "357",
                      "name": "GT_GESCHW_VORWAERTS_MAX_KMH",
                      "label": "Forward Speed max km/h",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              },
              {
                  "id": "92679",
                  "key": "GT_SCHNITTBREITE_CM_22_0",
                  "name": "GT_SCHNITTBREITE_CM",
                  "label": null,
                  "attrtype": "int",
                  "value_once": "",
                  "value_to": 0,
                  "value_from": 22,
                  "internalValue": "22_0",
                  "unit": "CM",
                  "parent": {
                      "id": "269",
                      "name": "GT_SCHNITTBREITE_CM",
                      "label": "Cutting Width in cm",
                      "exportToShop": true,
                      "exportToChannable": true
                  }
              }
          ],
          "appimages": [
              {
                  "image": {
                      "mimetype": "image/png",
                      "update": "1633534961",
                      "filename": "127569_sba_robo_700_w_2_cut_out.png",
                      "fullpath": "https://pim.al-ko.com/products/inTouchApp/127569_sba_robo_700_w_2_cut_out.png"
                  }
              }
          ],
          "images": [
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1622545146",
                      "filename": "127569_sba_robo_700_w_2.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/127569_sba_robo_700_w_2.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1624537164",
                      "filename": "127446_sba_robo700_1.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/127446_sba_robo700_1.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1624537164",
                      "filename": "127447_sba_robo_700i_bedienung_app_liz_188_1.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/127447_sba_robo_700i_bedienung_app_liz_188_1.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1624537165",
                      "filename": "127447_sba_robo_700i_eng_005_1.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/127447_sba_robo_700i_eng_005_1.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1624537165",
                      "filename": "127447_sba_robo_700i_tragen_liz_208.jpg",
                      "fullpath": "https://pim.al-ko.com/products/magento/127447_sba_robo_700i_tragen_liz_208.jpg"
                  }
              },
              {
                  "image": {
                      "mimetype": "image/jpeg",
                      "update": "1675934342",
                      "filename": "Engineered_in_Germany_Mood_2022_neu.jpg",
                      "fullpath": "https://pim.al-ko.com/products/images/Engineered_in_Germany_Mood_2022_neu.jpg"
                  }
              }
          ]
      }
  }
]));