import { map, Observable, of } from 'rxjs';

import { Injectable } from '@angular/core';
import { ThingState } from '../../../core/domain/thingstate.type';
import { IDeviceRepository } from '../../../core/repositories/device.repository';
import { demoThings } from '../../../core/demo/things.demo';
import { environment } from '../../../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FotaStatus } from '../../../core/domain/fota-status.enum';
import { AlkoThing, ThingWeb } from 'src/app/core/domain/thing.type';
import { ThingMapper } from '@mappers/thing.mapper';

@Injectable({
  providedIn: 'root',
})
export class DeviceWebRepository extends IDeviceRepository {
  mapper = new ThingMapper();

  constructor(private http: HttpClient) {
    super();
  }

  setDesiredState(
    thingName: string,
    desiredState: any,
  ): Observable<ThingState> {
    throw new Error('Method not implemented.');
  }

  getReportedStateOfDevice(thingName: string): Observable<ThingState> {
    throw new Error('Method not implemented.');
  }

  getAllDevices(): Observable<AlkoThing[]> {
    const url = `${environment.baseUrl}/iot/things?pimInfo=true`;
    // const params = new HttpParams().set('pimInfo', 'true');
    return this.http.get<ThingWeb[]>(url).pipe(
      map((x) => {
        return x.map(this.mapper.mapFrom);
      }),
    );
  }

  getDeviceById(thingName: string): Observable<AlkoThing> {
    const url = `${environment.baseUrl}/iot/things/${thingName}`;
    return this.http.get<ThingWeb>(url).pipe(map(this.mapper.mapFrom));
  }

  override getFotaStatus(
    thingName: string,
  ): Observable<{ fotaStatus: FotaStatus; targetVersion: string }> {
    throw new Error('Method not implemented.');
  }
}
