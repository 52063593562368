import { Thing } from '@al-ko/types';
import { BehaviorSubject, Observable, of, startWith, switchMap } from 'rxjs';
import { IDeviceInteractor } from '../contracts/idevice.interactor';
import { GetAllDevicesUsecase } from '../../../core/usecases/device-usecases/get-all-devices.usecase';
import { GetDeviceByIdUsecase } from '../../../core/usecases/device-usecases/get-device-by-id.usercase';
import { Injectable } from '@angular/core';
import { ThingState } from '../../../core/domain/thingstate.type';
import { SubscribeToDeviceUsecase } from '../../../core/usecases/device-usecases/subscribe-to-device.usecase';
import { IDeviceConnectionRepository } from '../../../core/repositories/device-connection.repository';
import { GetMetaDataOfDeviceUsecase } from '../../../core/usecases/get-metadata-of-device.usecase';
import { MetaDataModel } from '../../../core/domain/metadata.type';

@Injectable({ providedIn: 'root' })
export class DeviceInteractor extends IDeviceInteractor {
  // @TODO: add local-datastorage for thing-state.
  // add function to just get last local state, and only if there is none request it.
  // or return local and request afterwards the latest reported stsate
  private cachedThings: Thing[] | undefined = undefined;
  private thingStates: { [thingName: string]: BehaviorSubject<ThingState> } =
    {};

  constructor(
    private getAllDevicesUsecase: GetAllDevicesUsecase,
    private getDeviceByIdUsecase: GetDeviceByIdUsecase,
    private connectionRepo: IDeviceConnectionRepository,
    private getMetadataUsecase: GetMetaDataOfDeviceUsecase,
    // private subscribeToDeviceUsecase: SubscribeToDeviceUsecase,
  ) {
    super();
  }
  override getDeviceList(): Observable<Thing[]> {
    if (this.cachedThings) {
      return this.getAllDevicesUsecase.execute().pipe(
        startWith(this.cachedThings),
        switchMap((updatedThingList) => {
          this.cachedThings = updatedThingList;
          return of(updatedThingList);
        }),
      );
    } else {
      return this.getAllDevicesUsecase.execute().pipe(
        switchMap((thingList) => {
          this.cachedThings = thingList;
          return of(thingList);
        }),
      );
    }
  }
  override getDevice(thingName: string): Observable<Thing> {
    //  query current state
    const o = this.getDeviceByIdUsecase.execute(thingName);
    o.subscribe((thing) => {
      this.saveThingState(thingName, thing['thingState']['state']['reported']);
    });

    //  save current state in cache
    return o;
  }

  private saveThingState(thingName: string, thingState: ThingState) {
    if (this.cachedThings) {
      const max = this.cachedThings.length;
      for (let i = 0; i < max; i++) {
        if (this.cachedThings[i].thingName == thingName) {
          if (this.cachedThings[i]['thingState']) {
            this.cachedThings[i]['thingState'] = {
              state: { reported: thingState },
            };
          } else {
            console.log('test:', this.cachedThings[i]);
            this.cachedThings[i]['thingState'] = thingState;
          }
          break;
        }
      }
    }
  }

  override subscribeToDevice(thingName: string): Observable<any> {
    return this.connectionRepo.subscribe(thingName);
    //  TODO: ggf selbst darauf subscriben, um updated in dne lokalen cache zu speichern
  }
  override unsubscribeToDevice(thingName: string): void {
    return this.connectionRepo.unsubscribe(thingName);
  }

  override getCachedDevice(thingName: string): Thing {
    if (this.cachedThings) {
      const max = this.cachedThings.length;
      for (let i = 0; i < max; i++) {
        if (this.cachedThings[i].thingName == thingName) {
          return this.cachedThings[i];
        }
      }
    }
    return undefined;
  }

  override getDeviceMetadata(thingName: string): Observable<MetaDataModel[]> {
    return this.getMetadataUsecase.execute(thingName);
  }
  override createDeviceMetadata(thingName: string): Observable<any> {
    throw new Error('Method not implemented.');
  }
}
