import {
  ApplicationConfig,
  CSP_NONCE,
  importProvidersFrom,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { AlkoApiInterceptor } from './presenter/interceptor/alko-api.interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DeviceWebRepository } from './data/datasources/remote/device-web.repository';
import { IDeviceRepository } from './core/repositories/device.repository';
import { IDeviceInteractor } from './data/interactors/contracts/idevice.interactor';
import { DeviceInteractor } from './data/interactors/implementations/device.interactor';
import { DATA_DEVICE_IOC } from './data/data.ioc';
import { DecimalPipe } from '@angular/common';
import { IDeviceConnectionRepository } from './core/repositories/device-connection.repository';
import { WebsocketRepository } from './data/datasources/remote/websocket.repository';
import { IMetaRepository } from './core/repositories/meta-information.repository';
import { MetaRepository } from './data/datasources/remote/meta.repository';
import { MetaMockRepository } from './data/datasources/local/meta-mock.repository';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimations(),
    importProvidersFrom(BrowserAnimationsModule),
    DecimalPipe,
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlkoApiInterceptor,
      multi: true,
    },
    provideOAuthClient(),
    {
      // https://angular.io/guide/security#xss
      provide: CSP_NONCE,
      useValue: globalThis.myRandomNonceValue,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
    {
      provide: IDeviceRepository,
      useClass: DeviceWebRepository,
    },
    {
      provide: IDeviceConnectionRepository,
      useClass: WebsocketRepository,
    },
    {
      provide: IMetaRepository,
      useClass: MetaMockRepository,
      // useClass: MetaRepository,
    },
    ...DATA_DEVICE_IOC,
  ],
};
