import {
  ApplicationConfig,
  CSP_NONCE,
  importProvidersFrom,
  LOCALE_ID,
} from '@angular/core';
import { provideRouter, withComponentInputBinding } from '@angular/router';

import { routes } from './app.routes';
import {
  BrowserAnimationsModule,
  provideAnimations,
} from '@angular/platform-browser/animations';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { OAuthModule, provideOAuthClient } from 'angular-oauth2-oidc';
import { AlkoApiInterceptor } from './presenter/interceptor/alko-api.interceptor';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { DeviceWebRepository } from './data/datasources/remote/device-web.repository';
import { IDeviceRepository } from './core/repositories/device.repository';
import { IDeviceInteractor } from './data/interactors/contracts/idevice.interactor';
import { DeviceInteractor } from './data/interactors/implementations/device.interactor';
import { DATA_DEVICE_IOC } from './data/data.ioc';
import { DecimalPipe, registerLocaleData } from '@angular/common';
import { IDeviceConnectionRepository } from './core/repositories/device-connection.repository';
import { WebsocketRepository } from './data/datasources/remote/websocket.repository';
import { IMetaRepository } from './core/repositories/meta-information.repository';
import { MetaRepository } from './data/datasources/remote/meta.repository';
import { MetaMockRepository } from './data/datasources/local/meta-mock.repository';
import { environment } from '../environments/environment';
import { IPimRepository } from './core/repositories/pim.repository';
import { PimRepository } from './data/datasources/remote/pim-web.repositry';
import { IStorageRepository } from './core/repositories/storage.repository';
import { LocalStorageRepository } from './data/datasources/local-storage.repositroy';
import { IHistoryDataRepository } from './core/repositories/history-data.repository';
import { HistoryDataRepository } from './data/datasources/remote/history-data-web.repository';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { PendingRequestInterceptor } from './presenter/interceptor/pending-request.interceptor';
import { MAT_DATE_FORMATS, MatNativeDateModule } from '@angular/material/core';
import { FormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { MatInputModule } from '@angular/material/input';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);
// registerLocaleData(localeDe, 'de-DE');

export const appConfig: ApplicationConfig = {
  providers: [
    importProvidersFrom(
      MatNativeDateModule,
      MatSelectModule,
      MatInputModule,
      FormsModule,
      NgxDaterangepickerMd.forRoot(),
    ),
    // importProvidersFrom( //<-- not working
    //   OAuthModule.forRoot({
    //     resourceServer: {
    //       allowedUrls: [
    //         environment.baseUrl,
    //         'https://staging.al-ko.com/v1/iot/things',
    //         'https://staging.al-ko.com',
    //         'https://www.staging.al-ko.com',
    //         'staging.al-ko.com',
    //       ],
    //       sendAccessToken: true,
    //     },
    //   }),
    // ),
    {
      provide: LOCALE_ID,
      useValue: 'de-DE',
    },

    provideRouter(routes, withComponentInputBinding()),
    provideAnimations(),
    importProvidersFrom(BrowserAnimationsModule),
    DecimalPipe,
    // provideHttpClient(),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AlkoApiInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: PendingRequestInterceptor,
      multi: true,
    },
    provideOAuthClient(),
    {
      // https://angular.io/guide/security#xss
      provide: CSP_NONCE,
      useValue: globalThis.myRandomNonceValue,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        subscriptSizing: 'dynamic',
      },
    },
    {
      provide: IDeviceRepository,
      useClass: DeviceWebRepository,
    },
    {
      provide: IStorageRepository,
      useClass: LocalStorageRepository,
    },
    {
      provide: IPimRepository,
      useClass: PimRepository,
    },
    {
      provide: IHistoryDataRepository,
      useClass: HistoryDataRepository,
    },
    {
      provide: IDeviceConnectionRepository,
      useClass: WebsocketRepository,
    },
    {
      provide: IMetaRepository,
      // useClass: MetaMockRepository,
      useClass: MetaRepository,
    },
    ...DATA_DEVICE_IOC,
  ],
};
