import { Component } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatIconModule } from '@angular/material/icon';
import { NavigationMenuComponent } from './navigation-menu/navigation-menu.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MainToolbarComponent } from './main-toolbar/main-toolbar.component';
import { MatButtonModule } from '@angular/material/button';


@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [ 
    MatSidenavModule,
    NavigationMenuComponent,
    MatToolbarModule,
    MainToolbarComponent,
    MatIconModule,
    MatButtonModule,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent {
  public isContentPage: boolean = false;

  constructor(private router: Router) {
    
  }

  ngAfterViewInit(): void {

    this.router.events.pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event) => { 
        const navigationEvent = event as NavigationEnd;
        this.isContentPage = navigationEvent.urlAfterRedirects.startsWith('/pages');
      });
  }
}
