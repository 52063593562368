import { Provider } from '@angular/core';
import { IDeviceInteractor } from './interactors/contracts/idevice.interactor';
import { DeviceInteractor } from './interactors/implementations/device.interactor';

export const DATA_DEVICE_IOC: Provider[] = [
  {
    provide: IDeviceInteractor,
    useClass: DeviceInteractor,
  },
];
