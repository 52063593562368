import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, subscribeOn, switchMap, tap } from 'rxjs/operators';
import { OAuthService } from 'angular-oauth2-oidc';

const DOMAIN_production = 'api.al-ko.com';
const DOMAIN_staging = 'staging.al-ko.com';
const DOMAIN_dev = 'dev.al-ko.com';

@Injectable()
export class AlkoApiInterceptor implements HttpInterceptor {
  constructor(
    private authService: OAuthService,
    @Inject(LOCALE_ID) public locale: string,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler,
  ): Observable<HttpEvent<unknown>> {
    if (this.isAlkoDomain(request)) {
      const req = this.addAcceptedLanguage(
        this.addAuthenticationToRequest(request),
      );
      return next.handle(req);
    } else {
      return next.handle(request);
    }
  }

  isAlkoDomain(request: HttpRequest<unknown>): boolean {
    return (
      request.url.includes(DOMAIN_production) ||
      request.url.includes(DOMAIN_staging) ||
      request.url.includes(DOMAIN_dev)
    );
  }

  hasAuthentication(request: HttpRequest<unknown>): boolean {
    return request.headers.get('Authentication') !== null;
  }

  addAuthenticationToRequest(
    request: HttpRequest<unknown>,
  ): HttpRequest<unknown> {
    const token = this.authService.getAccessToken();
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  addAcceptedLanguage(request: HttpRequest<unknown>): HttpRequest<unknown> {
    return request.clone({
      setHeaders: {
        'Accept-Language': this.locale === 'en-US' ? 'de-DE' : this.locale,
      },
    });
  }
}
