import { AlkoCulture } from '@al-ko/types';
import { inject, Injectable } from '@angular/core';
import { IUserService } from '@interactors/contracts/iuser.service';
import { GetAlkocultureUsecase } from '@usecases/user-usecases/get-alkoCulture.usecase';
import { GetSapidUsecase } from '@usecases/user-usecases/get-sapId.usecase';
import { hasRoleUsecase } from '@usecases/user-usecases/has-role.usecase';

@Injectable({ providedIn: 'root' })
export class AlkoUserService extends IUserService {
  private usecaseGetAlkoCulture = inject(GetAlkocultureUsecase);
  private usecaseHasRole = inject(hasRoleUsecase);
  private usecaseGetSapCustomerId = inject(GetSapidUsecase);

  override getAlkoCulture(): AlkoCulture {
    return this.usecaseGetAlkoCulture.execute();
  }

  override hasRole(roleName: string): boolean {
    return this.usecaseHasRole.execute(roleName);
  }

  override getSapCustomerId(): string {
    return this.usecaseGetSapCustomerId.execute();
  }
}
