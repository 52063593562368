import { Injectable } from '@angular/core';
import { UseCase } from '../../base/use-case';
import { Observable } from 'rxjs';
import { IPimRepository } from '../../repositories/pim.repository';
import { ArticleMinInfo } from 'src/app/presenter/services/shopnanny.service';

@Injectable({
  providedIn: 'root',
})
export class SearchByArticleNumberUsecase
  implements UseCase<string, ArticleMinInfo[]>
{
  private pimRepo: IPimRepository;

  execute(articleNumber: string): Observable<ArticleMinInfo[]> {
    return this.pimRepo.searchProduct(articleNumber);
  }
}
