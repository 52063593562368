import { PimInformation } from '@al-ko/types';
import { map, Observable } from 'rxjs';
import { IPimRepository } from '../../../core/repositories/pim.repository';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { Injectable } from '@angular/core';
import { ErrorDescription } from '../../../core/domain/error-description.type';

@Injectable({
  providedIn: 'root',
})
export class PimRepository extends IPimRepository {
  constructor(private http: HttpClient) {
    super();
  }

  override getProductInformation(
    articleNumber: string,
  ): Observable<PimInformation> {
    const url = `${environment.baseUrl}/pim/articles/${articleNumber}`;
    return this.http.get<PimInformation>(url);
  }

  override getProductInformations(
    articleNumbers: string[],
  ): Observable<{ [articleNumber: string]: PimInformation }> {
    const url = `${environment.baseUrl}/pim/articles/`;
    return this.http.post<{ [articleNumber: string]: PimInformation }>(url, {
      articleNumbers: articleNumbers,
    });
  }

  override getSparepartsOfProduct(
    articleNumber: string,
  ): Observable<PimInformation[]> {
    throw new Error('Method not implemented.');
  }

  override getErrorDescriptions(): Observable<{
    [errorCode: number]: ErrorDescription;
  }> {
    throw new Error('Method not implemented.');
  }

  override getTranslations(
    translationPool: string,
  ): Observable<{ [key: string]: string }> {
    const url = `${environment.baseUrl}/pim/translations/${translationPool}`;
    return this.http.get<{ [key: string]: string }>(url);
  }
}
