import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '../../../../environments/environment';

export const OauthConfig: AuthConfig = {
  // Url of the Identity Provider
  // issuer: 'https://alkogtidentity.azurewebsites.net',
  issuer: environment.issuer,

  // URL of the SPA to redirect the user to after login
  // redirectUri: window.location.origin + '/index.html',
  // redirectUri: 'http://localhost:4200/oauth',
  redirectUri: environment.oauthCallbackUrl,

  // The SPA's id. The SPA is registerd with this id at the auth-server
  // clientId: 'server.code',
  clientId: 'ServiceDashboard',

  // Just needed if your auth server demands a secret. In general, this
  // is a sign that the auth server is not configured with SPAs in mind
  // and it might not enforce further best practices vital for security
  // such applications.
  dummyClientSecret: 'LgZYcSmc41TMH4G',

  responseType: 'code',

  // set the scope for the permissions the client should request
  // The first four are defined by OIDC.
  // Important: Request offline_access to get a refresh token
  // The api scope is a usecase specific one
  scope: 'openid offline_access alkoCustomerId alkoCulture roles introspection',

  showDebugInformation: true,

  // customQueryParams: {
  //   alkoCulture: 'DE'
  // }

  loginUrl: environment.logoutUrl,
  logoutUrl: environment.logoutUrl,
  tokenEndpoint: environment.tokenEndpoint,
  postLogoutRedirectUri: 'http://localhost:4200/home',

  skipIssuerCheck: true,
  sessionChecksEnabled: true,

  useSilentRefresh: true,
  silentRefreshTimeout: 5 * 60 * 1000,
  // silentRefreshRedirectUri: environment.tokenEndpoint,
  silentRefreshRedirectUri: environment.oauthCallbackUrl,
};
