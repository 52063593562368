import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { LayoutComponent } from './presenter/components/layout/layout.component';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ThingType } from '@al-ko/types';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, LayoutComponent, MatIconModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'dashboard';

  constructor(
    private iconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    this.registerIcons();
  }

  private registerIcons() {
    //  Robolinho
    this.iconRegistry.addSvgIcon(
      ThingType.robolinho,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/svg/robolinho.svg',
      ),
    );

    //  non-connected device
    this.iconRegistry.addSvgIcon(
      ThingType.nonsmart,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/svg/alko-logo.svg',
      ),
    );

    this.iconRegistry.addSvgIcon(
      ThingType.powerhead,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/svg/02_Rasen_Benzinrasenmäher.svg',
      ),
    );

    this.iconRegistry.addSvgIcon(
      ThingType.tractor,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/svg/02_Rasen_Rasentraktor.svg',
      ),
    );

    this.iconRegistry.addSvgIcon(
      ThingType.waterwork,
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        './assets/images/svg/03_Wasser_Gartenpumpen.svg',
      ),
    );
  }
}
