import { Observable, of } from 'rxjs';
import {
  IMetaRepository,
  MetaDataResponse,
} from '../../../core/repositories/meta-information.repository';
import { Injectable } from '@angular/core';
import { MetaDataModel } from '../../../core/domain/metadata.type';

@Injectable({
  providedIn: 'root',
})
export class MetaMockRepository extends IMetaRepository {
  constructor() {
    super();
  }

  override getMetadata(thingName: string): Observable<MetaDataResponse> {
    const mock = {
      thingName: thingName,
      queriedAttributes: [
        {
          attribute: 'entryPoints',
          attributeValue: ['eins', 'zwei', 'Startpunkt 3'],
        },
      ],
    };
    return of(mock);
  }

  override createMetadata(
    thingName: string,
    metadata: any,
  ): Observable<MetaDataModel> {
    return of();
  }
}
