import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authGuard: CanActivateFn = async (route, state) => {
  const oauthService = inject(OAuthService);
  const router = inject(Router);

  if (oauthService.hasValidAccessToken()) {
    return true;
  } else {
    console.log(
      '[authGuard] accesstokenexp: ',
      oauthService.getAccessTokenExpiration(),
    );

    oauthService.tokenEndpoint = environment.tokenEndpoint;
    try {
      console.log('[authGuard] try to refresh token');
      console.log('[authGuard] refresh_token:', oauthService.getRefreshToken());
      const r = await oauthService.refreshToken();
      console.log('[authGuard]  refresh token response:', r);
    } catch (ex) {
      console.log('error on refresh:', ex);
    }
    if (oauthService.hasValidAccessToken()) {
      console.log('refresh worked. :-)');
      return true;
    }

    router.navigateByUrl('/login');
    return false;
    // const redirectUrl: UrlTree = inject(Router).parseUrl('/login');
    // return redirectUrl;
  }
};
