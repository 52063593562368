<!-- <div class="container">
  <button mat-raised-button (click)="onLogin()" color="primary">Login!</button>
</div> -->

<div class="bg-container">
  <div class="container box-white">
    <div class="row top-area fit-in-box padding-container">
      <div class="col-md-3"></div>
      <div class="col-md-6 fit-in-box">
        <img src="assets/images/AL-KO_logo.png" class="logo" />
      </div>
      <div class="col-md-3"></div>
    </div>

    <div class="row middle-area padding-container">
      <div class="col-md-4 kringel-holder">
        <img src="assets/images/sba-icon.png" class="kringel" />
      </div>
      <div class="col-md-8">
        <div class="container">
          <div class="row" style="padding-bottom: 20px">
            <button
              i18n="
                Text of Login Button|If the use rist not logged in yet, he will
                will see a loginmask.@@loginButtonText"
              mat-button
              status="info"
              class="button-white"
              style="color: black"
              (click)="onLogin()"
            >
              LOGIN
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row bottom-area">
      <div class="col">
        <a
          href="https://alko-garden.de/impressum"
          i18n="@@imprint"
          i18n-href="@@imprintUrl"
          >imprint</a
        >
      </div>
      <div class="col">
        <a href="https://alko-garden.com/privacy" i18n="@@dataPrivacy" i18n
          >data privacy</a
        >
      </div>
      <div class="col">
        <img src="assets/images/primepulse.png" class="member-of-primepuls" />
      </div>
    </div>
  </div>
</div>
