import { Injectable } from '@angular/core';
import { IStorageRepository } from '../../core/repositories/storage.repository';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageRepository extends IStorageRepository {
  override saveData(key: string, value: any): void {
    // ggf merge data instead of overwrite

    localStorage.setItem(key, JSON.stringify(value));
  }

  override getData(key: string) {
    // ggf decode data
    try {
      const data = localStorage.getItem(key);
      return JSON.parse(data);
    } catch (e) {
      console.error('[LocalStorage] error:', e);
      return undefined;
    }
  }

  override removeData(key: string): void {
    localStorage.removeItem(key);
  }

  override clear(): void {
    localStorage.clear();
  }
}
