import { Observable, Subject, Subscription } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { ThingState } from '../../../core/domain/thingstate.type';
import { IDeviceConnectionRepository } from '../../../core/repositories/device-connection.repository';
import { environment } from '../../../../environments/environment';
import { OAuthService } from 'angular-oauth2-oidc';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class WebsocketRepository extends IDeviceConnectionRepository {
  private subscriptions: { [thingName: string]: WebSocketSubject<any> } = {};

  constructor(private authService: OAuthService) {
    super();
  }

  private hasSubscriptionOfThing(thingName: string) {
    if (this.subscriptions[thingName] !== undefined) {
      return this.subscriptions[thingName].closed != false;
    }
    return false;
  }

  private subscribeToDevice(thingName: string) {
    const accessToken = this.authService.getAccessToken();
    const url = `${environment.websocketUrl}?thingName=${thingName}&Authorization=${accessToken}`;
    this.subscriptions[thingName] = webSocket(url);
  }

  override subscribe(thingName: string): Observable<ThingState> {
    if (!this.hasSubscriptionOfThing(thingName)) {
      this.subscribeToDevice(thingName);
    }

    return this.subscriptions[thingName];
  }

  override unsubscribe(thingName: string): void {
    if (this.hasSubscriptionOfThing(thingName)) {
      this.subscriptions[thingName].unsubscribe();
      // this.subscriptions[thingName] = undefined;
    }
  }
}
