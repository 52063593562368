import { Injectable } from '@angular/core';
import { UseCase } from '../../base/use-case';
import { Observable } from 'rxjs';
import { Thing } from '@al-ko/types';
import { IDeviceRepository } from '../../repositories/device.repository';

@Injectable({
  providedIn: 'root',
})
export class GetAllDevicesUsecase implements UseCase<void, Thing[]> {
  constructor(private deviceRepository: IDeviceRepository) {}

  execute(params: void): Observable<Thing[]> {
    return this.deviceRepository.getAllDevices();
  }
}
