import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { UseCase } from '../base/use-case';
import { IMetaRepository } from '../repositories/meta-information.repository';
import { MetaDataModel } from '../domain/metadata.type';

@Injectable({
  providedIn: 'root',
})
export class GetMetaDataOfDeviceUsecase
  implements UseCase<string, MetaDataModel>
{
  constructor(private metaRepo: IMetaRepository) {}

  execute(thingName: string): Observable<MetaDataModel[]> {
    return this.metaRepo
      .getMetadata(thingName)
      .pipe(map((m) => m.queriedAttributes));
  }
}
