import { inject, Injectable } from '@angular/core';
import { AlkoCulture, HistoryData } from '@al-ko/types';
import { UseCase } from '../../base/use-case';
import { OAuthService } from 'angular-oauth2-oidc';
import { jwtDecode } from 'jwt-decode';
import { AlkoJwtToken } from '../../domain/alko-jwt-token.type';

@Injectable({
  providedIn: 'root',
})
export class GetAlkocultureUsecase implements UseCase<undefined, AlkoCulture> {
  private oauthService = inject(OAuthService);

  execute(): AlkoCulture {
    const accessToken = this.oauthService.getAccessToken();
    const decodedToken = jwtDecode<AlkoJwtToken>(accessToken);
    return decodedToken.alkoCulture as AlkoCulture;
  }
}
