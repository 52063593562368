import { inject, Injectable } from '@angular/core';
import { AlkoCulture, HistoryData } from '@al-ko/types';
import { UseCase } from '../../base/use-case';
import { OAuthService } from 'angular-oauth2-oidc';
import { jwtDecode } from 'jwt-decode';
import { AlkoJwtToken } from '../../domain/alko-jwt-token.type';

@Injectable({
  providedIn: 'root',
})
export class hasRoleUsecase implements UseCase<string, boolean> {
  private oauthService = inject(OAuthService);

  execute(role: string): boolean {
    const accessToken = this.oauthService.getAccessToken();
    const decodedToken = jwtDecode<AlkoJwtToken>(accessToken);
    return decodedToken.role.includes(role);
  }
}
