import { CanActivateFn, Router, UrlTree } from '@angular/router';
import { inject } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { environment } from '../../../environments/environment';

export const authGuard: CanActivateFn = async (route, state) => {
  const oauthService = inject(OAuthService);
  if (oauthService.hasValidAccessToken()) {
    return true;
  } else {
    console.log('accesstokenexp: ', oauthService.getAccessTokenExpiration());
    // oauthService.useSilentRefresh = true;
    console.log('tokenendpoint?', oauthService.tokenEndpoint); //tokenendpoint is not
    console.log('env.tokenendpoint?', environment.tokenEndpoint); //tokenendpoint is not
    oauthService.tokenEndpoint = environment.tokenEndpoint;
    try {
      const r = await oauthService.refreshToken();
    } catch (ex) {
      console.log('error on refresh:', ex);
    }
    if (oauthService.hasValidAccessToken()) {
      console.log('refresh worked. :-)');
      return true;
    }
    console.log('refresh has not worked. :-(');
    const redirectUrl: UrlTree = inject(Router).parseUrl('/login');
    return redirectUrl;
  }
};
