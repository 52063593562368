<div class="search-box">
  <input
    type="search"
    class="search-input"
    placeholder="Search"
    [(ngModel)]="searchTerm"
    (input)="onInputChange()"
  />
  <i class="material-icons search-icon">search</i>

  @if (filteredThings.length) {
    <ul class="suggestions">
      @for (thing of filteredThings; track $index) {
        <li (click)="selectSuggestion(thing.thingName)">
          <mat-icon
            fontSet="material-symbols-outlined"
            class="alko-suggestion-item-icon"
            [svgIcon]="thing.thingType"
          ></mat-icon>
          {{ thing.accessInformation.accessAlias }}
          @if (thing.accessInformation.issuerEmail) {
            <span class="suggestion-item-owner"
              >({{ thing.accessInformation.issuerEmail }})</span
            >
          }
          <span class="suggestion-item-serialnumber"
            >[{{ thing.thingAttributes.serialNumber }}]</span
          >
        </li>
      }
    </ul>
  }
</div>
