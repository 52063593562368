import { Component, inject } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import {
  MatDialogTitle,
  MatDialogContent,
  MatDialogActions,
  MatDialogClose,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';

type CardAppearance = {
  value: string;
  viewValue: string;
};

@Component({
  selector: 'app-main-settings-dialog',
  imports: [
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    MatButtonModule,
    MatDialogTitle,
    MatDialogContent,
    MatDialogActions,
    MatSelectModule,
  ],
  templateUrl: './main-settings-dialog.component.html',
  styleUrl: './main-settings-dialog.component.scss',
})
export class MainSettingsDialogComponent {
  readonly dialogRef = inject(MatDialogRef<MainSettingsDialogComponent>);

  readonly cardApearances: CardAppearance[] = [
    { value: 'outlined', viewValue: 'outlined' },
    { value: 'raised', viewValue: 'raised' },
  ];

  onNoClick(): void {
    this.dialogRef.close();
  }
}
