import { Component } from '@angular/core';
import { OauthConfig } from '../oauth.config';
import { ActivatedRoute, Router } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';


@Component({
    selector: 'app-login-callback',
    imports: [],
    templateUrl: './login-callback.component.html',
    styleUrl: './login-callback.component.scss'
})
export class LoginCallbackComponent {

  constructor(
    private oauthService: OAuthService, 
    private route: ActivatedRoute,
    private router: Router) {
    
  }
  
  ngAfterViewInit(): void {
    this.oauthService.configure(OauthConfig);
    this.oauthService.loadDiscoveryDocument();
    this.login();
  }

  private async login() {

    await this.oauthService.tryLoginCodeFlow();

    if (this.oauthService.hasValidAccessToken()) {
      this.router.navigate(['pages']);
    } else {
      this.router.navigate(['login']);
      
    }

  }


}
