<div
  class="main-container center"
  style="
    display: -webkit-flex;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: red;
    width: 100%;
    height: 100%;
  "
>
  <div class="container">
    <!-- Loading... -->

    <div class="row">
      <!-- <div class="col-md-4"></div> -->
      <div class="center">
        <div
          class="rotating"
          style="
            width: 100%;
            height: 100%;
            line-height: 100px;
            text-align: center;
            display: -webkit-flex;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-align-items: center;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            overflow: hidden;
          "
        >
          <!-- callback.component -->
          <img
            src="assets/images/sba-icon.png"
            style="
              margin-right: auto;
              text-align: center;
              margin-left: auto;
              width: 200px;
            "
          />
        </div>
        <!-- <div class="col-md-4"></div> -->
      </div>
    </div>

    <!-- <div class="row" style="text-align: center; margin-top: 30px;">
      <h2 style="color:white; margin-left: auto; margin-right: auto; font-size: 20pt">
        ... login ...
      </h2>
    </div> -->
  </div>
</div>
