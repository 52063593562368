import { Injectable } from '@angular/core';
import { UseCase } from '../../base/use-case';
import { Observable } from 'rxjs';
import { Thing } from '@al-ko/types';
import { IDeviceRepository } from '../../repositories/device.repository';

@Injectable({
  providedIn: 'root',
})
export class GetDeviceByIdUsecase implements UseCase<string, Thing> {
  constructor(private deviceRepository: IDeviceRepository) {}

  execute(params: string): Observable<Thing> {
    return this.deviceRepository.getDeviceById(params);
  }
}
