import { Component, inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from '../../../services/user.service';
import { MainSettingsDialogComponent } from './main-settings-dialog/main-settings-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MainSearchComponent } from './main-search/main-search.component';

@Component({
    selector: 'app-main-toolbar',
    imports: [
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MainSearchComponent,
    ],
    templateUrl: './main-toolbar.component.html',
    styleUrl: './main-toolbar.component.scss'
})
export class MainToolbarComponent implements OnInit {
  readonly dialog = inject(MatDialog);
  // readonly dialogRef = inject(MatDialogRef<MainSettingsDialogComponent>);

  public userName: string = '';

  constructor(
    private authService: OAuthService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    this.userName = this.userService.getUserName();
  }

  onLogout() {
    console.log('logging out ...');
    this.authService.logOut(false);
  }

  onOpenSettingsDialg() {
    this.dialog.open(MainSettingsDialogComponent);
  }
}
